import React from "react";

import Category from "../../components/layout/Category/Category";
const CreateCategories = () => {
  return (
    <div>
      <Category />
    </div>
  );
};

export default CreateCategories;
