import React, { useState, useEffect } from "react";
import { Button, Box, Grid, Typography } from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
// import Category from "../../components/layout/Category/Category";
// import MediaCard from "../../components/features/CategorryCard/Card";
import { axiosRequestAuth } from "../../../../services/api/api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
// import styles from "./Mint.module.css";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Divider from "@mui/material/Divider";
import BulkMint from "../../../../assets/Miniting/Bulk.jpg";
import SingleMint from "../../../../assets/Miniting/Single.jpg";
import MintingCard from "../../../Minting/mintingCard/mintingCard";
import BackgroundWrapper from "../../../layout/Shared/BackgroundWrapper/BackgroundWrapper";

const CollectionMint = () => {
  const txtcolor = "#f1f1f1";
  const [collectionData, setCollectionData] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const param = useParams();

  const getCategoriesById = async (id) => {
    setLoading(true);
    try {
      const response = await axiosRequestAuth(
        "get",
        `category/collection/${id}`
      );

      setLoading(false);
      setCollectionData(response.data.data);
      // toast.success(response.data.message);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    if (param.id) {
      getCategoriesById(param.id);
    }
  }, []);

  return (
    <BackgroundWrapper>
      <Box>
        <Grid container spacing={2}>
          <Grid item lg={2}>
            {" "}
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                pt: 1,
                ml: 8,
              }}
            >
              <Button
                size="large"
                sx={{ color: txtcolor }}
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon fontSize="large" sx={{ color: txtcolor }} />
              </Button>
            </Box>
          </Grid>
          <Grid item lg={8}>
            <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
              <Typography
                variant="h4"
                sx={{ color: txtcolor, fontWeight: 600 }}
              >
                Minting Type
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={2}></Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={1} />
          <Grid item lg={4}>
            {" "}
            <Box sx={{ display: "flex", justifyContent: "center", pt: 3 }}>
              <Typography
                variant="h4"
                sx={{ color: txtcolor, fontWeight: 600 }}
              >
                Bulk Mint
              </Typography>
              {/* <MintingCard image={BulkMint} /> */}
            </Box>{" "}
            <Box sx={{ display: "flex", justifyContent: "center", pt: 3 }}>
              {" "}
              <Link to="/minting-bulk">
                <MintingCard image={BulkMint} border={false} />
              </Link>
            </Box>
          </Grid>
          <Grid item lg={2}>
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 2,
                bgcolor: "background.paper",
                color: "text.secondary",
                "& svg": {
                  m: 1,
                },
              }}
            > */}
            {/* <Box> */}
            {/* <Divider
              orientation="vertical"
              variant="middle"
              sx={{
                mt: 0,
                height: 24, // Adjust the height as needed
                bgcolor: "#AF22C8", // Optionally, you can keep this for the color
              }}
            /> */}
            {/* </Box> */}
            {/* </Box> */}
          </Grid>
          <Grid item lg={4}>
            {" "}
            <Box sx={{ display: "flex", justifyContent: "center", pt: 3 }}>
              <Typography
                variant="h4"
                sx={{ color: txtcolor, fontWeight: 600 }}
              >
                Single Mint
              </Typography>
              {/* <MintingCard image={BulkMint} /> */}
            </Box>{" "}
            <Box sx={{ display: "flex", justifyContent: "center", pt: 3 }}>
              <Link to="/minting-single">
                <Box>
                  <MintingCard image={SingleMint} border={true} />
                </Box>
              </Link>
            </Box>
          </Grid>
          <Grid item lg={1} />
        </Grid>
      </Box>
    </BackgroundWrapper>
  );
};

export default CollectionMint;
