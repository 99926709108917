import CoreumBlockchain from "../../../../../assets/MidBannerIcons/CoreumBlockchain.svg";
import TronBlockchain from "../../../../../assets/MidBannerIcons/TronBlockchain.svg";
import XRPLedger from "../../../../../assets/MidBannerIcons/XRPLedger.svg";

const dummyData = [
  {
    id: 1,
    title: "CoreumBlockchain",
    url: CoreumBlockchain,
  },
  {
    id: 2,
    title: "TronBlockchain",
    url: TronBlockchain,
  },
  {
    id: 3,
    title: "XRPLedger",
    url: XRPLedger,
  },
];

export default dummyData;
