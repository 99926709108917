import React, { useState, useEffect } from "react";

//Mui Component
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Box,
  InputAdornment,
  useTheme,
  useMediaQuery,
  TextField,
  Tooltip,
  Modal,
  IconButton,
  Skeleton,
} from "@mui/material";

import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
//Mui Icons

import CloseIcon from "@mui/icons-material/Close";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import HandshakeIcon from "@mui/icons-material/Handshake";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import placeholder from "../../../assets/Category/placeholder.png";
//video Import from Assets Folder

import SellFor from "../TabsComponent/SellFor";

import NFTDialog from "../NFTDialog/NFTDialog";

import {
  getLikedNfts,
  getNftDetail,
  resetSellState,
  saleNFT,
} from "../../../services/redux/MyNftApiReducer/MyNftApiReducer";

import { useLocation } from "react-router-dom";
import CustomLoader from "../../shared/customLoader/customLoader";
import { setText } from "../../../services/redux/TextReducer/TextReducer";
import { axiosRequestAuth, baseURL, imageURL } from "../../../services/api/api";

const NFTCard = ({
  nft,
  isForSale = false,
  forSale,
  upforSale = false,
  isFeatured = false,
  borderColor = "#AF22C8",
  bgColor = "black",
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Simulate loading time (you would replace this with actual data fetching)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Reduce time for testing, adjust as needed

    return () => clearTimeout(timer);
  }, []);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const matchesLikes = useMediaQuery(theme.breakpoints.up("md"));
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  //Component States
  const [loading1, setLoading1] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [ModelImage, setModelImage] = useState(null);
  const [sellForValue, setSellForValue] = useState("");
  const dispatch = useDispatch();
  const { nftDetails } = useSelector((state) => state.nft);
  const { userId, token: isAuthenticated } = useSelector(
    (state) => state.userNft
  );

  const maxWidth = 345;

  const [visible, setVisible] = useState(false);
  const [animationStyle, setAnimationStyle] = useState({});
  const user = localStorage.getItem("accnum");
  const [offerValue, setOfferValue] = useState("");
  const [nftDataOffer, setNftDataOffer] = useState([]);
  const [nftData, setNftData] = useState([]);
  const [nftLike, setNftLike] = useState(
    nft?.is_liked || location.pathname.includes("nfts/favorite")
  );
  const [nftCount, setNftCount] = useState(nft?.like_count);
  let ipfsImage = "";
  let ipfsVideo = "";
  // Determine which image to show
  let sashImage = null;
  //console.log(nft);
  if (user === nft?.owner) {
    if (nft?.for_sale === true) {
      sashImage = "https://xmart.art/img/sashes/left-for-sale.png";
    } else {
      sashImage = null;
    }
  } else if (nft?.for_sale === false) {
    sashImage = "https://xmart.art/img/sashes/left-make-an-offer.png";
  } else if (nft?.for_sale === true) {
    sashImage = "https://xmart.art/img/sashes/left-for-sale.png";
  }
  // ipfsImage = `https://ipfs.io/ipfs/${cid}`;

  ipfsImage = nft?.imageUrl ? `${nft?.imageUrl}` : null;
  // if (nft?.metadata?.image?.startsWith("ipfs:")) {
  //   const cid = nft?.metadata?.image?.split("ipfs://")[1];
  // }
  // else {
  //   ipfsImage = nft?.metadata?.image ? nft?.metadata?.image : null;
  // }

  if (nft?.metadata?.video?.startsWith("ipfs:")) {
    const cid = nft?.metadata?.video?.split("ipfs://")[1];
    ipfsVideo = `https://ipfs.io/ipfs/${cid}`;
  } else {
    ipfsVideo = nft?.metadata?.video;
  }

  console.log(ipfsImage, nft?.imageUrl, "ipfsImage");
  // useEffect(() => {
  //   if (ipfsImage) {
  //     setLoading(false);
  //   }
  // }, [ipfsImage]);
  // Function to handle the "Buy NFT" action

  // Function to handle the "Win me" action
  const handleWinMe = () => {
    alert("Win me");
  };

  //open Dialog When Click Image
  const handleCardClick = () => {
    const apiDta = {
      nft_id: nft.id,
    };
    setVisible(false);
    setOfferValue("");
    dispatch(getNftDetail(apiDta));
    dispatch(setText(""));
    if (nftDetails) {
      console.log(nftDetails, "nftDetails");
      setModelImage(nft?.imageUrl);
      setIsDialogOpen(true);
    }
    // Logs the entire nft object to the console
  };

  //Close Dialog
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const handleOfferCancel = () => {
    setOfferValue(""); // Reset the offer value if needed
  };

  const handleSellForChange = (newValue) => {
    setSellForValue(newValue);

    // Additional logic with the new value
  };
  // Selling NFT Dispatch
  const handleSellClick = () => {
    // const updatedNft = { ...nft_id, price: offerValue };
    dispatch(setText("Waiting xaman approval ..."));

    const sellOffer = {
      nft_id: nft.id,
      price: parseFloat(offerValue),
      //price: parseInt(offerValue),
    };
    dispatch(saleNFT(sellOffer));
  };

  // Selling NFT
  useEffect(() => {
    if (offerValue) {
      setVisible(true);
      setAnimationStyle({
        animation: "slideInUp 0.5s ease-out forwards",
      });
    } else if (visible) {
      setAnimationStyle({
        animation: "slideDown 0.5s ease-out forwards",
      });

      // Wait for animation to complete before hiding the div
      const timeout = setTimeout(() => {
        setVisible(false);
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [offerValue, visible]);

  // Function to determine button Appearance Component
  const renderActionButton = () => {
    if (nft?.owner === user) {
      return location.pathname.includes("recently-for-sale") ? (
        <Button
          variant="contained"
          size="small"
          startIcon={<LocalOfferIcon />}
          // You may want to define this function if you need specific logic for making offers
          sx={{
            textTransform: "none",
            bgcolor: "#5A5A5A",
            color: "black",
            width: "100%",
            fontFamily: "goodTime",
            "&:hover": { bgcolor: "#5A5A5A" },
          }}
        >
          My NFT
        </Button>
      ) : (
        <Box>
          {isAuthenticated ? (
            <TextField
              fullWidth
              type="number"
              label="Sell for"
              size="small"
              value={offerValue}
              onChange={(e) => setOfferValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "e" || e.key === "E") {
                  e.preventDefault();
                }
              }}
              InputProps={{
                style: { color: "white" },
                inputProps: { min: 0 },
                endAdornment: (
                  <InputAdornment position="end">
                    <>
                      {!offerValue ? (
                        <Typography sx={{ color: "white" }}>XRP</Typography>
                      ) : (
                        <IconButton onClick={handleOfferCancel}>
                          <CloseIcon sx={{ color: "white" }} />
                        </IconButton>
                      )}
                    </>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& label.Mui-focused": {
                  color: "white",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#AF22C8",
                  },
                  "&:hover fieldset": {
                    borderColor: "#AF22C8",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#AF22C8",
                  },
                },
                "& .MuiInputLabel-root": {
                  // Label color
                  color: "white",
                },
                "& .MuiInputBase-input": {
                  // Input text color
                  color: "white",
                },
                "& .MuiInputBase-input::placeholder": {
                  // Placeholder text color
                  color: "white",
                  opacity: 1,
                },
              }}
            />
          ) : (
            <Button
              variant="contained"
              size="small"
              startIcon={<LocalOfferIcon />}
              onClick={handleCardClick} // You may want to define this function if you need specific logic for making offers
              sx={{
                textTransform: "none",
                bgcolor: "#D4AF37",
                color: "black",
                width: "100%",
                fontFamily: "goodTime",
                "&:hover": { bgcolor: "#D4AF37" },
              }}
            >
              Make an Offer
            </Button>
          )}
        </Box>
      );
    } else if (nft?.for_sale === false) {
      return (
        <Button
          variant="contained"
          size="small"
          startIcon={<LocalOfferIcon />}
          onClick={handleCardClick} // You may want to define this function if you need specific logic for making offers
          sx={{
            textTransform: "none",
            bgcolor: "#D4AF37",
            color: "black",
            width: "100%",
            fontFamily: "goodTime",
            "&:hover": { bgcolor: "#D4AF37" },
          }}
        >
          Make an Offer
        </Button>
      );
    } else if (nft?.for_sale === true) {
      return (
        <Button
          variant="contained"
          size="small"
          startIcon={<ShoppingBasketIcon sx={{ color: "black" }} />}
          onClick={handleCardClick}
          sx={{
            bgcolor: "#1DAA1D",
            color: "black",
            width: "100%",
            textTransform: "none",
            fontFamily: "goodTime",
            "&:hover": { bgcolor: "#008000" },
          }}
        >
          Buy NFT
        </Button>
      );
    } else if (upforSale === true) {
      return;
    } else {
      return (
        <Button
          variant="contained"
          size="small"
          startIcon={
            <HandshakeIcon sx={{ fontSize: "30px", color: "black" }} />
          }
          onClick={handleWinMe}
          sx={{
            textTransform: "none",
            bgcolor: "#53A0C9",
            color: "black",
            width: "100%",
            fontWeight: "bold",
            fontFamily: "goodTime",
            "&:hover": { bgcolor: "#53A0C9" },
          }}
        >
          Win me
        </Button>
      );
    }
  };

  const handleButtonClick = () => {
    const nftData = { ...nft }; // Create a new object by spreading 'nft'
    nftData.price = sellForValue;

    dispatch(saleNFT(nftData));
  };
  //console.log(nftData?.id, "asdasd");
  const handleLikeButton = async (nftId) => {
    setNftLike(true);
    setNftCount(nftCount + 1);
    try {
      const data = { nft_id: nftId?.toString(), user_id: userId?.toString() };

      const response = await axiosRequestAuth("post", "v2/like", data);
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error("Login Required");
      } else {
        //toast.error("Something went wrong. Please try again!");
      }
    }
    // getLikeButton();
  };
  const handleUnlikeButton = async (nftId) => {
    setNftLike(false);
    if (nftCount - 1 >= 0) setNftCount(nftCount - 1);

    try {
      const data = { nft_id: nftId?.toString(), user_id: userId?.toString() };
      const response = await axiosRequestAuth(
        "delete",
        "v2/like/removelike",
        data
      );

      if (response?.status) {
        if (location.pathname.includes("nfts/favorite")) {
          const data = { user_id: userId?.toString() };
          dispatch(getLikedNfts(data));
        }
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error("Login Required");
      } else {
        //toast.error("Something went wrong. Please try again!");
      }
    }
    // getLikeButton();
  };

  useEffect(() => {
    setNftData(nftDetails?.data?.nft);
    setNftDataOffer(nftDetails?.data?.nft);
  }, [nftDetails]);

  const lowestPrice = getLowestPrice(nft);

  function getLowestPrice(nft) {
    // Ensure nft.nft_offers is not empty and is an array
    if (
      !nft ||
      !Array.isArray(nft.sell_offers) ||
      nft.sell_offers.length === 0
    ) {
      return null; // Return null if no offers or not in the correct format
    }

    // Initialize lowestPrice with the price of the first offer
    let lowestPrice = nft.sell_offers[0].price;

    // Iterate through the array starting from the second element
    for (let i = 1; i < nft.sell_offers.length; i++) {
      const offer = nft.sell_offers[i];
      // Check if the current offer's price is lower than the lowestPrice
      if (offer.price < lowestPrice) {
        lowestPrice = offer.price; // Update lowestPrice if a lower price is found
      }
    }

    return lowestPrice;
  }

  return (
    <>
      {isDialogOpen && (
        <NFTDialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          imageUrl={ModelImage}
          nft={nftData}
          offersData={nftDataOffer}
        />
      )}

      {/* DIALOG COMPOENNT */}

      <Box
        sx={{
          position: "relative",
          maxWidth: maxWidth,
          minWidth: 100,
          bgcolor: isFeatured
            ? "background: linear-gradient(45deg, #d4d4d4, #a6a6a6)"
            : "black",
          border: `1px solid ${borderColor}`,

          borderRadius: "10px",
          p: isFeatured ? 0 : 0.5,
        }}
      >
        {sashImage && (
          <Box sx={{ position: "absolute", top: -7, left: -7, zIndex: 2 }}>
            <img
              src={sashImage}
              alt={isForSale ? "For Sale" : "Make Offer"}
              style={{ width: "80px", height: "80px" }}
            />
          </Box>
        )}

        <Card
          sx={{
            position: "relative",
            background: isFeatured
              ? "radial-gradient(ellipse farthest-corner at right , #ffffff 0%, #d1d1d1 10%, #A8A9AD 20%, #f0f0f0 70%, #636060 100%)"
              : "black",

            borderRadius: isFeatured ? "10px" : 0,
          }}
        >
          {nft?.metadata?.video ? (
            <Box
              sx={{
                px: 0.5,
                pt: 0.5,
                display: "block",
                width: "100%",
                height: "250px", // Default height
                borderRadius: "15px",
                overflow: "hidden",
                cursor: "pointer",
                [theme.breakpoints.down("md")]: {
                  // When the theme hits 'xs' breakpoint
                  height: "220px", // Adjust the height for extra small screens
                },
              }}
              onClick={handleCardClick}
            >
              <video
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "5px",
                }}
                autoPlay
                loop
                muted
              >
                <source src={ipfsVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Box>
          ) : (
            ///////////////////////////////   Image Section   ////////////////////////////////////////////////
            <Box
              sx={{
                px: 0.5,
                pt: 0.5,
                display: "block",
                width: "100%",
                minHeight: "250px", // Default height
                borderRadius: "15px",
                overflow: "hidden",
                cursor: "pointer",
                [theme.breakpoints.down("md")]: {
                  // When the theme hits 'xs' breakpoint
                  height: "220px", // Adjust the height for extra small screens
                },
              }}
              onClick={handleCardClick}
            >
              {(loading || !imageLoaded) && (
                <Skeleton
                  variant="rectangular"
                  animation="pulse" // Change to 'pulse' for a simple fade animation
                  width="327px"
                  height="345px"
                  sx={{
                    borderRadius: "10px",
                    bgcolor: "#9e9e9e", // Set the background color to gray
                  }}
                />
              )}

              <CardMedia
                component="img"
                sx={{
                  display: loading || !imageLoaded ? "none" : "block",
                  px: 0.5,
                  pt: 0.5,
                  minWidth: 100,
                  maxWidth: maxWidth,
                  height: "auto",
                  borderRadius: "10px",
                  objectFit: "fill",
                  objectPosition: "center",
                  objectFit: "cover",
                  [theme.breakpoints.down("md")]: {
                    // When the theme hits 'xs' breakpoint
                    height: "240px", // Adjust the height for extra small screens
                  },
                }}
                onLoad={handleImageLoad}
                image={ipfsImage ? ipfsImage : placeholder}
                alt={nft?.metadata?.name}
              />
            </Box>
          )}

          {/* CARD SECTION */}
          <CardContent sx={{ p: 1 }}>
            <Typography
              gutterBottom
              variant="p"
              component="p"
              sx={{
                p: 0,
                m: 0,
                color: "#AF22C8",
                display: "flex",
                justifyContent: "space-between",
                fontFamily: "goodTime",
                fontSize: "11px", // Default font size
                [theme.breakpoints.down("md")]: {
                  fontSize: "10px", // Font size on medium and smaller devices
                },
                [theme.breakpoints.down("sm")]: {
                  fontSize: "9px", // Font size on small and smaller devices
                },
              }}
            >
              <Tooltip title={nft?.metadata?.name}>
                {nft?.metadata?.name
                  ? nft?.metadata?.name.length > 25
                    ? `${nft?.metadata?.name.slice(0, 25)}...`
                    : nft?.metadata?.name
                  : "N/A"}

                {/* <FavoriteBorderIcon
                sx={{ p: 0, m: 0, color: "#AF22C8", cursor: "pointer" }}
              /> */}
              </Tooltip>
            </Typography>

            {/* TIITLE 2 */}

            <Typography
              gutterBottom
              variant="p"
              component="p"
              sx={{
                textAlign: "start",
                fontSize: "12px", // Default font size
                [theme.breakpoints.down("md")]: {
                  fontSize: "10px", // Font size on medium and smaller devices
                },
                [theme.breakpoints.down("sm")]: {
                  fontSize: "11px", // Font size on small and smaller devices
                },
                p: 0,
                m: 0,
                // mb: 1,
                color: isFeatured ? "black" : "white",

                // color: isFeatured ? "black" : "white",
                fontFamily: "goodTime",
              }}
            >
              <Tooltip title={nft?.metadata?.collection?.family}>
                {nft?.metadata?.collection?.family
                  ? nft?.metadata?.collection?.family.length > 20
                    ? `${nft?.metadata?.collection?.family.slice(0, 20)}...`
                    : nft?.metadata?.collection?.family
                  : "N/A"}
              </Tooltip>
            </Typography>

            <Typography
              gutterBottom
              variant="p"
              component="p"
              sx={{
                fontSize: "14px",
                py: 1,
                m: 0,
                // mb: 1,
                textAlign: "start",
                color: isFeatured ? "black" : "white",
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
                //fontFamily:'goodTime'
              }}
            >
              <Box>
                <span
                  style={{
                    fontSize: "13px",
                    color: "#AF22C8",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {"lowest Price".charAt(0).toUpperCase() +
                    "lowest Price".slice(1).toLowerCase()}
                  &nbsp;{" "}
                </span>
                <span style={{ fontSize: "12px" }}>
                  {typeof lowestPrice === "number"
                    ? `${lowestPrice}${
                        nft?.nft_offers?.length > 1
                          ? `, + ${nft.nft_offers.length - 1}...`
                          : ""
                      }`
                    : "N/A"}
                </span>
              </Box>
              <Box>
                <span style={{ fontSize: "13px", color: "#AF22C8" }}>
                  {" "}
                  Rank&nbsp;{" "}
                </span>
                <span style={{ fontSize: "12px" }}> {nft?.rank || "N/A"} </span>
              </Box>

              <span style={{ fontSize: "10px", color: "#AF22C8" }}></span>
            </Typography>

            {/* Conditional Rendering for Buttons */}
            {forSale ? (
              <SellFor onValueChange={handleSellForChange} />
            ) : isFeatured && !forSale ? (
              <Button
                variant="contained"
                size="small"
                startIcon={<ShoppingBasketIcon />}
                onClick={handleCardClick}
                sx={{
                  bgcolor: "white",
                  color: "black",
                  border: "2px solid #AF22C8",
                  width: "100%",
                  textTransform: "none",
                  fontFamily: "goodTime",
                  "&:hover": {
                    bgcolor: "#AF22C8",
                    color: "white",
                    "& .MuiSvgIcon-root": { color: "white" },
                  },
                }}
              >
                Buy Now
              </Button>
            ) : !isFeatured && !forSale ? (
              loading1 && renderActionButton()
            ) : null}
          </CardContent>
          {/* CARD SECTION */}

          {!isFeatured && (
            <CardActions
              sx={{
                borderTop: "1px solid #2B2A2A",
                bgcolor: "black",
                justifyContent: "space-between",
                p: 0,
                m: 0,
              }}
            >
              <Button sx={{ color: "white" }}>
                {nftLike ? (
                  <FavoriteIcon
                    onClick={() => {
                      if (isAuthenticated) {
                        setTimeout(() => {
                          handleUnlikeButton(nft.id);
                        }, 100);
                      } else {
                        toast.error("You need to login first to access NFTs");
                      }
                    }}
                  />
                ) : (
                  <FavoriteBorderIcon
                    onClick={() => {
                      if (isAuthenticated) {
                        setTimeout(() => {
                          handleLikeButton(nft.id);
                        }, 100);
                      } else {
                        toast.error("You need to login first to access NFTs");
                      }
                    }}
                  />
                )}
              </Button>
              {/* <Typography
                variant="body2"
                sx={{ color: "white", pr: { xs: 3, md: 0 } }}
              >
                3/123
              </Typography> */}
              {/* {matchesLikes && ( */}
              <Typography variant="body2" sx={{ color: "white", pr: 3 }}>
                {nftCount || 0} Likes
              </Typography>
              {/* )} */}
            </CardActions>
          )}
        </Card>
      </Box>
      {/* APPROVE IN WALLET COMPONENT */}
      <div>
        {sellForValue && (
          <div
            style={{
              position: "fixed",
              left: 0,
              bottom: 0,
              width: "100%",
              zIndex: 99999999,
            }}
          >
            <Button
              sx={{
                background: "#AF22C8",
                width: "100%", // Set the button width to 100%
                textAlign: "center",
                p: 2,
                color: "white", // Text color
                // You can add more styles here if needed
                "&:hover": {
                  bgcolor: "#AF22C8", // Keeps the background color same on hover
                  // Add any additional hover styles here
                },
              }}
              onClick={handleButtonClick}
            >
              <FingerprintIcon sx={{ mr: 1 }} />
              Approve in Wallet
            </Button>
          </div>
        )}
      </div>
      {isLoading && (
        <Box
          sx={{
            position: "fixed", // Or 'absolute' if you want it relative to its nearest positioned ancestor
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.6)", // Optional: adds a white translucent background
            zIndex: 1000, // Ensure it's above all other content
          }}
        >
          <CustomLoader width={"60%"} text={"Please wait ..."} />{" "}
        </Box>
      )}
      {visible ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            bottom: 0,
            width: "100%",
            zIndex: 99999999,
            ...animationStyle,
          }}
        >
          <Button
            sx={{
              background: "#AF22C8",
              width: "100%", // Set the button width to 100%
              textAlign: "center",
              p: 2,
              color: "white", // Text color
              // You can add more styles here if needed
              "&:hover": {
                bgcolor: "#AF22C8", // Keeps the background color same on hover
                // Add any additional hover styles here
              },
            }}
            onClick={handleSellClick}
          >
            <FingerprintIcon sx={{ mr: 1 }} />
            Approve in Wallet
          </Button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default NFTCard;
