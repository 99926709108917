import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axiosRequestAuth, axiosRequest } from "../../api/api";

export const getFeaturedNft = createAsyncThunk(
  "nft/getFeaturedNft",
  async (dataDetails, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequest("get", "v2/featured");
      // Check if the response is successful
      // (This depends on how your axiosRequestAuth is structured)

      // console.log("API recentlyForSaleNft", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const carouselNft = createAsyncThunk(
  "nft/carouselNft",
  async (dataDetails, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth("get", "v2/collection/carousel");
      // console.log("API carouselNft", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getCategories = createAsyncThunk(
  "nft/getCategories",
  async (dataDetails, { rejectWithValue }) => {
    try {
      // Assuming saleDetails contains 'user_account' and 'offset'
      const response = await axiosRequestAuth("get", "category");

      // console.log("API getCategories", response);
      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data; // Assuming the response data is the payload you want
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const landingPageSlice = createSlice({
  name: "landingPage",
  initialState: {
    featuredNftData: { data: [], loading: false, error: null },
    carouselNftData: { data: [], loading: false, error: null },
    categoriesData: { data: [], loading: false, error: null },
    categoryId: null,
  },

  reducers: {
    resetApproveSate: (state) => {
      // console.log("call");
      // state.approvRes = "";
    },
    setCategoryId: (state, action) => {
      state.categoryId = action.payload;
    },
    resetCategoryId: (state) => {
      state.categoryId = null;
    },
  },

  extraReducers: {
    // featured Details nft
    [getFeaturedNft.pending]: (state) => {
      state.featuredNftData.loading = true;
    },
    [getFeaturedNft.fulfilled]: (state, action) => {
      state.featuredNftData.loading = false;
      state.featuredNftData.data = action.payload;
      // Update state based on the successful win
      // ...
    },
    [getFeaturedNft.rejected]: (state, action) => {
      // state.featuredNftData.loading = false;
      // state.featuredNftData.error = action.payload;
    },

    // carouselNft Details nft
    [carouselNft.pending]: (state) => {
      state.carouselNftData.loading = true;
    },
    [carouselNft.fulfilled]: (state, action) => {
      state.carouselNftData.loading = false;
      state.carouselNftData.data = action.payload;
      // Update state based on the successful win
      // ...
    },
    [carouselNft.rejected]: (state, action) => {
      state.carouselNftData.loading = false;
      state.carouselNftData.error = action.payload;
    },

    // getCategories Details nft
    [getCategories.pending]: (state) => {
      state.categoriesData.loading = true;
    },
    [getCategories.fulfilled]: (state, action) => {
      state.categoriesData.loading = false;
      state.categoriesData.data = action.payload;
      // Update state based on the successful win
      // ...
    },
    [getCategories.rejected]: (state, action) => {
      state.categoriesData.loading = false;
      state.categoriesData.error = action.payload;
    },
  },
});
export default landingPageSlice.reducer;

export const { resetApproveSate, resetCategoryId, setCategoryId } =
  landingPageSlice.actions;
