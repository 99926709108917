import React, { useState, useEffect } from "react";
import { Button, Box, Grid, Typography, Divider } from "@mui/material";

import { axiosRequestAuth } from "../../services/api/api";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  getAllCollection,
  getCollectionById,
} from "../../services/redux/NftApiReducer/NftApiReducer";
import CustomLoader from "../../components/shared/customLoader/customLoader";
import UserCollectionCard from "../../components/features/UserCollectionCard/UserCollectionCard";
import {
  setCollectionId,
  setCollectionName,
  setTaxonId,
} from "../../services/redux/CollectionReducer/CollectionReducer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const containerStyle = {
  position: "relative",
  width: "100%",
  minHeight: "100vh",
  background:
    "linear-gradient(225deg, #731686 1%, transparent 10%, transparent 80%, #af22c8 100%)", // Linear gradient background
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  paddingBottom: "20px",
};
const contentStyle = {
  position: "relative",
  top: "0px",
  width: "100%",
};

const AllCollection = () => {
  const txtcolor = "#f1f1f1";
  const { collection, loading } = useSelector((state) => state.nft);
  const [collectionData, setCollectionData] = useState(
    collection?.data?.collections || []
  );
  const [orderList, setOrderList] = useState(
    collection?.data?.order?.sorted_ids
  );
  const orderedRows = collection?.data?.order?.sorted_ids
    ?.map((orderId) =>
      collection?.data?.collections?.find((row) => row?.id === orderId)
    )
    ?.filter((item) => item !== undefined);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const param = useParams();

  useEffect(() => {
    const apiBody = {
      collection_type: "C",
    };

    dispatch(getAllCollection(apiBody));
  }, []);
  const handleData = (e) => {
    dispatch(setTaxonId(e.taxon_id));
    dispatch(setCollectionName(e.name));
    dispatch(setCollectionId(e.id));

    // setTimeout(() => {
    navigate(`/category-collection/${e.vanity_name}`);
    // }, 2000); // 2000 milliseconds = 2 seconds
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xl={2} lg={2} sm={2} xs={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  pt: 3,
                  pl: 1,
                }}
              >
                <Button
                  size="large"
                  sx={{ color: txtcolor }}
                  onClick={() => navigate(-1)}
                >
                  <ArrowBackIcon fontSize="large" sx={{ color: txtcolor }} />
                </Button>
              </Box>
            </Grid>
            <Grid item xl={8} lg={8} sm={8} xs={10}>
              <Box
                sx={{ display: "flex", justifyContent: "center", pt: 4, pb: 4 }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: txtcolor,
                    fontWeight: 600,
                    fontFamily: "goodTime",
                  }}
                >
                  XRP Collections
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={2} lg={2} sm={2} xs={1}>
              <Box
                sx={{ display: "flex", justifyContent: "center", pt: 4, pr: 4 }}
              ></Box>
            </Grid>
          </Grid>
          <Divider
            variant="middle"
            sx={{ mt: 0, backgroundColor: "#AF22C8", mx: 4, mb: 4 }}
          />
          <Box sx={{ p: 1, pb: 4, display: "flex", justifyContent: "center" }}>
            {!loading ? (
              <Grid container spacing={2} sx={{ pt: 4, px: 4 }}>
                {collectionData &&
                  orderedRows?.map((item, index) => (
                    <Grid
                      key={index}
                      item
                      lg={3}
                      md={4}
                      sm={6}
                      xs={12}
                      sx={{
                        py: 4,
                        pb: 2,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <UserCollectionCard data={item} onConfirm={handleData} />
                    </Grid>
                  ))}
              </Grid>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "80vh", // Set minimum height to fill the entire viewport
                }}
              >
                <CustomLoader width={"60%"} text={"Please wait ..."} />{" "}
              </Box>
            )}
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default AllCollection;
