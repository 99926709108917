import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/shared/customTable/customTable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BackgroundWrapper from "../../../components/layout/Shared/BackgroundWrapper/BackgroundWrapper";
import { Grid, Box, Typography, Button } from "@mui/material";
import { axiosRequestAuth } from "../../../services/api/api";
import toast from "react-hot-toast";

import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CustomModal from "../../../components/shared/customModal/customModal";
import CustomLoader from "../../../components/shared/customLoader/customLoader";

function AdminCategories() {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [orderList, setOrderList] = useState(null);
  // Example user data
  const navigate = useNavigate();

  const txtcolor = "#f1f1f1";
  // Handle the edit action
  const handleEditClick = (data) => {
    // Handle edit action for the selected user
    navigate(`/admin/categories/${data.id}`);
  };
  const handleDeleteClick = (data) => {
    // Handle edit action for the selected user
    // deleteFeatured(data.id);
    setOpenModal(true);
    setDeleteId(data.id);
  };
  useEffect(() => {
    getAllCategories();
  }, []);

  const getAllCategories = async () => {
    setLoading(true);
    try {
      const response = await axiosRequestAuth("get", "category");

      setCategories(response?.data?.data?.categories);
      setOrderList(response?.data?.data?.order);
      setLoading(false);
      // toast.success(response.data.message);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };
  // const handleViewClick = (data) => {
  //   // Handle edit action for the selected user
  //   // deleteFeatured(data.id);
  //   // setOpenModalView(true);
  //   // setDeleteId(data.id);
  // };

  const deleteCategories = async (id) => {
    setLoading(true);
    try {
      const response = await axiosRequestAuth(
        "post",
        `category/delete/${deleteId}`
      );

      toast.success(response?.data?.message);

      getAllCategories();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const columns = [
    { key: "name", label: "Name", dataType: "text" },
    { key: "thumbnail", label: "Thumbnail", dataType: "image" },
    { key: "createdAt", label: "Created At", dataType: "date" },
    // { key: "updatedAt", label: "Updated At", dataType: "date" },
  ];
  const modalContent = (
    <Box p={2}>
      <Typography variant="h6" sx={{ color: txtcolor }}>
        Are you sure you want to delete?
      </Typography>
      {/* Any additional content you want in the modal */}
    </Box>
  );

  return (
    <BackgroundWrapper>
      <Grid container spacing={1} pl={5} pr={10}>
        <Grid item lg={2}>
          {" "}
          <Box sx={{ display: "flex", justifyContent: "start", pt: 3, ml: 1 }}>
            <Button
              size="large"
              sx={{ color: txtcolor }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon fontSize="large" sx={{ color: txtcolor }} />
            </Button>
          </Box>
        </Grid>
        <Grid item lg={8}>
          <Box sx={{ display: "flex", justifyContent: "center", pt: 2, pb: 4 }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                color: "white",
                textAlign: "center",
                py: 2,
              }}
            >{`Categories`}</Typography>
          </Box>
        </Grid>
        <Grid item lg={2}>
          <Box sx={{ display: "flex", justifyContent: "center", pt: 3 }}>
            <Button
              size="large"
              variant="contained"
              sx={{
                bgcolor: "#af22c8",
                "&:hover": {
                  bgcolor: "#62136f", // Adjust the darkness level as needed
                },
              }}
              onClick={() => navigate("/category-add")}
            >
              {/* <AddIcon fontSize="large" sx={{ color: txtcolor }} /> */}
              Add Category
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid xl={2} lg={2} md={2} sm={0} xs={0} />
        <Grid xl={8} lg={8} md={8} sm={12} xs={12}>
          <>
            {!loading ? (
              <CustomTable
                type={"category"}
                draggable={true}
                data={categories}
                editable={true}
                columns={columns}
                showDeleteIcon={false}
                onEditClick={handleEditClick}
                onDeleteClick={handleDeleteClick}
                // onDeleteClick={handleDeleteClick}
                // onViewClick={handleViewClick}
                actions={true}
                showVisibleIcon={true}
                orderList={orderList}
                setOrderList={setOrderList}
              />
            ) : (
              <CustomLoader width="20%" text={"Please wait ..."} />
            )}
          </>
        </Grid>
        <Grid xl={2} lg={2} md={2} sm={0} xs={0} />
      </Grid>
      <CustomModal
        open={openModal}
        setOpenModal={setOpenModal}
        content={modalContent}
        onConfirm={deleteCategories}
      />
    </BackgroundWrapper>
  );
}

export default AdminCategories;
