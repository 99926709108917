import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const CustomModal = ({ open, setOpenModal, content, onConfirm }) => {
  const handleDelete = () => {
    onConfirm(); // Call the onConfirm function (deleteCollections) from the parent component
    setOpenModal(false); // Close the modal
  };

  return (
    <Modal
      componentsProps={{
        backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0.8)" } },
      }}
      open={open}
      onClose={() => setOpenModal(false)}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          pt: 6,
        }}
      >
        <Paper
          elevation={2}
          sx={{
            background: `linear-gradient(
              360deg,
              #000 0%,        /* Start with black at 0% */
              #301934 40%,       /* Gradually transition to black at 40% */
              #af22c8 90%,    /* Start the color transition at 60% */
              #af22c8 100%    /* Continue with the final color until 100% */
          )`,
            p: 4,
          }}
        >
          {content}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleDelete} // Call handleDelete on button click
              sx={{
                background: "#BF40BF",
                color: "white",
                width: "100%",
                "&:hover": {
                  backgroundColor: "white",
                  color: "black",
                  "& .MuiSvgIcon-root": {
                    color: "black",
                  },
                },
              }}
            >
              Delete
            </Button>
            <Button
              fullWidth
              variant="contained"
              sx={{
                ml: 1,
                background: "#818589",
                color: "white",
                width: "100%",
                border: "solid 1px white",
                "&:hover": {
                  backgroundColor: "white",
                  color: "black",
                  "& .MuiSvgIcon-root": {
                    color: "black",
                  },
                },
              }}
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default CustomModal;
