import React from "react";
import Loader from "../../../assets/logos/Loading.gif";
import { Box, Typography } from "@mui/material";

const CustomLoader = ({ width = "auto", text = "" }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%", // Ensure the loader covers the entire parent component
      }}
    >
      {/* Loader */}
      <img src={Loader} alt="Loading" style={{ width: width }} />

      {/* Optional text */}
      {text && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            variant="body2"
            sx={{ color: "white", fontFamily: "arial", fontSize: 18 }}
          >
            {text ? text : "Please wait ..."}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CustomLoader;
