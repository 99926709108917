import React, { useEffect, useState, useRef } from "react";
import { Box, Grid, Typography, Button, Tabs, Divider } from "@mui/material";

import { useDispatch } from "react-redux";

import { toast } from "react-hot-toast";
import { useSelector } from "react-redux/es/hooks/useSelector";

import styles from "./myAllNFTs.module.css"; // Import the CSS module

import { useNavigate, useParams } from "react-router-dom";
import NFTCard from "../../../components/features/NFTCard/NFTCard";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  getAllCollectionNFTVanity,
  getCollectionById,
} from "../../../services/redux/NftApiReducer/NftApiReducer";
import CollectionHeader from "../../../components/layout/Shared/CollectionHeader/CollectionHeader";
import useScrollPagination from "../../../hooks/useScrollPagination/useScrollPagination";
import CustomLoader from "../../../components/shared/customLoader/customLoader";
import ScrollToTopOnMount from "../../../utils/ScrollToTop/automaticScrollToTop/automaticScrollToTop";

const containerStyle = {
  position: "relative",
  width: "100%",
  minHeight: "100vh",
  background:
    "linear-gradient(225deg, #731686 1%, transparent 10%, transparent 80%, #af22c8 100%)", // Linear gradient background
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  paddingBottom: "20px",
};
const contentStyle = {
  position: "relative",
  top: "0px",
  width: "100%",
};

const AllNFTs = () => {
  let lastScrollPosition = 0;
  let is90vhReached = false;
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const param = useParams();
  const [myNFT, setMyNFT] = useState();
  const [collectionName, setCollectionName] = useState();
  const [collectionHeader, setCollectionHeader] = useState();

  const [loading1, setLoading] = useState(false);

  const navigate = useNavigate();
  const { collectionById, loading, VanityNfts } = useSelector(
    (state) => state.nft
  );

  const { buyNFTState, sellNFT } = useSelector((state) => state.mynft);

  const [offset, setOffset] = useState(1); // Initialize offset with 0

  const collection_id = useSelector((state) => state.collection?.collectionId);
  const [pages, setPages] = useState(1);
  useEffect(() => {
    setCollectionName(param.name);
    // Reset to the first page when myNFT data changes
  }, [param]);
  //////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (offset === 1) {
      fetchData();
    } else if (offset <= pages) {
      fetchData();
    }
  }, [offset]);

  function handleScroll() {
    const scrollPosition = window.scrollY;
    const viewportHeight = window.innerHeight;
    const pageHeight = document.documentElement.scrollHeight;

    if (
      !loading &&
      scrollPosition >= lastScrollPosition + 0.9 * viewportHeight
    ) {
      const newOffset = Math.floor(scrollPosition / (0.9 * viewportHeight));
      setOffset(newOffset + 1); // Add 1 because indexing starts from 0
      lastScrollPosition = newOffset * 0.9 * viewportHeight;
    }

    // Check if 90vh is reached
    if (!is90vhReached && scrollPosition >= pageHeight) {
      setOffset((prevOffset) => prevOffset + 1);
      is90vhReached = true;
    }

    // Reset is90vhReached when page reaches 90vh again
    if (is90vhReached && scrollPosition < 0.9 * pageHeight) {
      is90vhReached = false;
    }
  }

  function mergeArrays(existingData, newData) {
    // Ensure newData is not undefined or null
    const mergedData = newData ?? [];

    // Clear the existingData if offset is 1
    if (offset === 1) {
      existingData = [];
    }

    // Iterate over the new data and push each element to the existing array
    mergedData.forEach((item) => {
      existingData.push(item);
    });

    // Return the updated existing array
    return existingData;
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    return () => {
      // Perform cleanup here, for example, resetting the data
      setMyNFT([]);
    };
  }, []);

  useEffect(() => {
    setMyNFT((prevNFTs) => mergeArrays(prevNFTs, collectionById?.data?.nfts));

    if (offset === 1) {
      setPages(collectionById?.data?.collection_info?.total_pages);
    }
  }, [collectionById]);

  useEffect(() => {
    setMyNFT((prevNFTs) => mergeArrays(prevNFTs, VanityNfts?.data?.nfts));

    if (offset === 1) {
      setPages(VanityNfts?.data?.collection_info?.total_pages);
    }
  }, [VanityNfts]);

  useEffect(() => {
    setCollectionHeader(collectionById?.data || VanityNfts?.data);
  }, [VanityNfts, collectionById]);

  ////////////////////////////////////////////////////////////////////////

  const fetchData = () => {
    if (param.accountNumber && param.name) {
      const apiData = {
        vanity_collection_name: param.name,
        user_account: param.accountNumber,
        limit: 24,
        offset: offset,
      };
      dispatch(getAllCollectionNFTVanity(apiData));
    } else {
      let account = localStorage.getItem("accnum");
      const apiData = {
        vanity_name: param.collections,
        limit: 24,
        offset: offset,
        userAccount: account,
      };
      dispatch(getCollectionById(apiData));
    }
    // Simulated delay for demonstration purposes
    // Replace with your actual API call
  };
  // Execute the initial data fetch on component mount
  // useEffect(() => {
  //   if (offset <= 3) {
  //     fetchData();
  //   }
  // }, [param.accountNumber, param.name, offset]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    console.log(sellNFT, "sellNFTs");
    setLoading(loading || sellNFT?.loading); // Reset to the first page when myNFT data changes
  }, [loading, sellNFT]);
  console.log(loading1, "sellNFTs loading ");
  function renderContent() {
    return (
      <>
        <ScrollToTopOnMount />
        {myNFT && myNFT.length > 0 ? (
          <Grid container spacing={4} sx={{ paddingInline: 4 }}>
            {myNFT.map((items) => (
              <Grid
                item
                lg={3}
                md={6}
                sm={6}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center", // Add this for vertical centering
                }}
                key={items.id}
              >
                <NFTCard nft={items} isForSale={true} borderColor="#AF22C8" />
              </Grid>
            ))}
          </Grid>
        ) : (
          !loading1 &&
          myNFT && (
            <Box sx={{ display: "flex", justifyContent: "center", pt: 6 }}>
              <Typography
                variant="h6"
                sx={{ color: "#f1f1f1", fontFamily: "goodTime" }}
              >
                No NFT's Found!
              </Typography>
            </Box>
          )
        )}
        {/* Loader */}
        {loading1 && (
          <Box height={"100vh"}>
            <CustomLoader width="15%" text={"Please wait ..."} />
          </Box>
        )}
      </>
    );
  }

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            {" "}
            {/* <Box
              sx={{ display: "flex", justifyContent: "start", pt: 1, ml: 1 }}
            ></Box> */}
          </Grid>

          <Grid item xs={8}>
            <>
              {!loading1 && (
                <Box sx={{}}>
                  <CollectionHeader Data={collectionHeader} loading={loading} />
                </Box>
              )}
            </>
          </Grid>

          <Grid item xs={2}></Grid>
        </Grid>
        {!loading1 && (
          <Divider
            variant="middle"
            sx={{ mb: 4, backgroundColor: "#AF22C8", mx: 6, mt: 2 }}
          />
        )}
        {loading1 ? (
          <Box height={"100vh"}>
            <CustomLoader width="15%" text={"Please wait ..."} />
          </Box>
        ) : (
          <Box sx={{ mb: 2 }}>{renderContent()}</Box>
        )}
      </div>
    </div>
  );
};
export default AllNFTs;
