import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/system";

// Define custom styled components
const CustomTableContainer = styled(TableContainer)({
  background: "transparent", // Remove background color
  color: "white", // Set text color to white
});

const CustomTableCell = styled(TableCell)({
  color: "white", // Set text color to white
  borderBottom: "1px dotted #AF22C8", // Light white bottom border
});

const CustomTableHeadCell = styled(TableCell)({
  color: "#AF22C8", // Set text color to red
  fontWeight: "bold", // Make the font bold
});

const CustomTableRow = styled(TableRow)({});

function createData(dateTime, description, byWho, value, image) {
  return { dateTime, description, byWho, value, image };
}

const rows = [
  createData(
    "30 Oct 23 @ 04:04 pm",
    "Sell Offer Accepted by Buyer",
    "BairyXrp",
    "-",
    "https://api.xmart.art/src/img/profilepicture/b/c/8/bc84577aa191403c1a37404526cf6424/1690262519611_440.jpg"
  ),
  // ... other rows
];

export default function HistoryTable() {
  return (
    <CustomTableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <CustomTableHeadCell>Date/Time</CustomTableHeadCell>
            <CustomTableHeadCell>Description</CustomTableHeadCell>
            <CustomTableHeadCell>By Who</CustomTableHeadCell>
            <CustomTableHeadCell>Value</CustomTableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <CustomTableRow key={index}>
              <CustomTableCell component="th" scope="row">
                {row?.dateTime}
              </CustomTableCell>
              <CustomTableCell>{row?.description}</CustomTableCell>
              <CustomTableCell>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Avatar
                    src={row?.image}
                    alt={row?.byWho}
                    sx={{ mr: 1, border: "2px solid #AF22C8" }}
                  />
                  {row?.byWho}
                </Box>
              </CustomTableCell>
              <CustomTableCell>{row?.value}</CustomTableCell>
            </CustomTableRow>
          ))}
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
}
