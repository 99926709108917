import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Button, Grid, Typography } from "@mui/material";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { useNavigate, useParams } from "react-router-dom";
import BackgroundWrapper from "../../../layout/Shared/BackgroundWrapper/BackgroundWrapper";
import { toast } from "react-hot-toast";
import { axiosRequestAuth } from "../../../../services/api/api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomInput from "../../../shared/customInput/customInput";
import { imageURL } from "../../../../services/api/api";
import CustomLoader from "../../../shared/customLoader/customLoader";

const CategoriesForm = () => {
  const [loading, setLoading] = useState(false);
  const bgcolor = "rgba(104, 103, 103, 0.5)";
  const txtcolor = "#f1f1f1";
  const navigate = useNavigate();

  const param = useParams();

  const [formDataImage, setFormDataImage] = useState(null);

  // setState for selected image

  const [selectedImage, setSelectedImage] = useState(null); // setState for selected image

  const handleImageClick = () => {
    // Programmatically trigger the file input when the box is clicked
    document.getElementById("image-upload-input").click();
  };
  const schema = yup
    .object({
      categoryName: yup.string().required("Category Name is required"),
      categoryImage: yup.mixed().required("Category Image is required"),
    })
    .required();

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setFormDataImage(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
      reset({ categoryImage: "" });
    }
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append("category_file", formDataImage);
    formData.append("category_name", data?.categoryName);
    // formData.append("user_account", user_account);
    if (param.id) {
      updateCategoriesByID(param.id, formData);
    } else {
      CreateCategories(formData);
    }
  };

  async function CreateCategories(formData) {
    setLoading(true);

    try {
      const response = await axiosRequestAuth("post", "category", formData);
      toast.success(response?.data?.message);

      setSelectedImage(null);
      setLoading(false);
      reset({
        categoryName: "",
      });
      navigate(-1);
      // toast.success(response.data.message);
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        toast.error("Login Required");
      } else {
        toast.error("Something went wrong. Please try again!");
      }
    }
  }
  const getCategoriesByID = async (id) => {
    setLoading(true);
    try {
      const response = await axiosRequestAuth("get", `category/${id}`);

      setFormDataImage(`${imageURL}${response?.data?.data?.thumbnail}`);
      setSelectedImage(`${imageURL}${response?.data?.data?.thumbnail}`);
      reset({
        categoryName: response?.data?.data?.name,
        categoryImage: `${imageURL}${response?.data?.data?.thumbnail}`,
      });
      // setCategories(response.data.data);
      setLoading(false);
      // toast.success(response.data.message);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong. Please try again!");
    }
  };

  const updateCategoriesByID = async (id, formData) => {
    setLoading(true);
    try {
      await axiosRequestAuth("post", `category/${id}`, formData);
      toast.success("Category updated successfully.");
      setFormDataImage();
      setSelectedImage();
      reset();
      // setCategories(response.data.data);
      setLoading(false);
      // toast.success(response.data.message);
      navigate(-1);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong. Please try again!");
    }
  };
  useEffect(() => {
    if (param.id) {
      getCategoriesByID(param.id);
    }
  }, []);
  return (
    <BackgroundWrapper>
      <Box>
        <Grid container spacing={2}>
          <Grid item lg={2}>
            {" "}
            <Box
              sx={{ display: "flex", justifyContent: "start", pt: 1, ml: 1 }}
            >
              <Button
                size="large"
                sx={{ color: txtcolor, mt: 1 }}
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon fontSize="large" sx={{ color: txtcolor }} />
              </Button>
            </Box>
          </Grid>
          <Grid item lg={8}>
            <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
              {param.id ? (
                <Typography
                  variant="h4"
                  sx={{ color: txtcolor, fontWeight: 600, mt: 1 }}
                >
                  Edit Category
                </Typography>
              ) : (
                <Typography
                  variant="h4"
                  sx={{ color: txtcolor, fontWeight: 600 }}
                >
                  Create Category
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
        {!loading ? (
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              direction: "row",
              justifyContent: "center",
              pt: 2,
            }}
          >
            <Grid
              item
              xl={4}
              lg={4}
              sm={4}
              sx={{
                p: 2,
              }}
            />

            <Grid
              item
              xl={4}
              lg={4}
              sm={4}
              sx={{
                p: 2,
              }}
            >
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
                id="image-upload-input"
              />

              <div
                onClick={handleImageClick}
                style={{
                  maxWidth: "100%",
                  minWidth: "250px",
                  height: "240px",
                  borderRadius: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  background: bgcolor,
                  border: `2px solid ${
                    errors.categoryImage ? "red" : "transparent"
                  }`,
                }}
              >
                {selectedImage ? (
                  <img
                    src={selectedImage}
                    alt="Selected"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                ) : (
                  <CloudUploadIcon style={{ fontSize: 48 }} />
                )}
              </div>
              <span style={{ color: "red", fontSize: 11 }}>
                {errors.categoryImage?.message}
              </span>
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                <Box
                  sx={{
                    justifyContent: "center",

                    py: 2,
                    pb: 2,
                    // background: "grey",
                  }}
                >
                  {" "}
                  <Typography variant="body1" sx={{ color: txtcolor }}>
                    Category Name*
                  </Typography>
                  <CustomInput
                    name="categoryName"
                    register={register}
                    errors={errors}
                    bgcolor={bgcolor}
                    txtcolor={txtcolor}
                    isFullWidth={true}
                  />
                </Box>

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  {!param.id ? (
                    <Button fullWidth variant="contained" type="submit">
                      Create
                    </Button>
                  ) : (
                    <Button fullWidth variant="contained" type="submit">
                      Update
                    </Button>
                  )}
                </Box>
                {/* </Box> */}
              </form>
            </Grid>
            <Grid
              item
              lg={4}
              xl={4}
              sm={4}
              sx={{
                p: 2,
              }}
            />
          </Grid>
        ) : (
          <CustomLoader width="15%" text={"Please wait ..."} />
        )}
      </Box>
    </BackgroundWrapper>
  );
};

export default CategoriesForm;
