import { combineReducers } from "redux";

import SocketReducer from "../SocketReducer/SocketReducer";
import CollectionReducer from "../CollectionReducer/CollectionReducer";
import NotificationReducer from "../NotificationReducer/NotificationReducer";
import NftApiReducer from "../NftApiReducer/NftApiReducer";
import UserNFTCountReducer from "../UserNFTCountReducer/UserNFTCountReducer";
import MyNftApiReducer from "../MyNftApiReducer/MyNftApiReducer";
import LoginReducers from "../LoginReducers/LoginReducers";
import LandingPageApiReducer from "../LandingPageApiReducer/LandingPageApiReducer";
import TextReducer from "../TextReducer/TextReducer";
import OffsetReducer from "../OffsetReducer/OffsetReducer";

export const reducer = combineReducers({
  socketR: SocketReducer,
  collection: CollectionReducer,
  notify: NotificationReducer,
  userNft: UserNFTCountReducer,
  nft: NftApiReducer,
  mynft: MyNftApiReducer,
  xumm: LoginReducers,
  landingPage: LandingPageApiReducer,
  text: TextReducer,
  offset: OffsetReducer,
});
