import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/shared/customTable/customTable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";

import * as yup from "yup";
import BackgroundWrapper from "../../../components/layout/Shared/BackgroundWrapper/BackgroundWrapper";
import {
  Grid,
  Box,
  Typography,
  Button,
  Modal,
  Paper,
  IconButton,
} from "@mui/material";
import { axiosRequestAuth } from "../../../services/api/api";
import toast from "react-hot-toast";

import { useNavigate } from "react-router-dom";
import CustomInput from "../../../components/shared/customInput/customInput";
import { CloseOutlined } from "@mui/icons-material";

import AddIcon from "@mui/icons-material/Add";
import CustomModal from "../../../components/shared/customModal/customModal";
import CustomMuiButton from "../../../components/shared/customButton/customButton";
import CustomLoader from "../../../components/shared/customLoader/customLoader";
function Admins() {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [admins, setAdmins] = useState();
  // Example user data
  const navigate = useNavigate();

  const txtcolor = "#f1f1f1";
  // Handle the edit action
  const bgcolor = "rgba(104, 103, 103, 0.5)";
  const schema = yup
    .object({
      user_account: yup.string().required("Wallet address is required"),
    })
    .required();

  const handelModalOpen = () => {
    setOpenModal(true);
  };
  const handleEditClick = () => {
    // setOpenModal(true);
    setOpenModal(true);
  };

  const handleDeleteClick = (data) => {
    setOpenSecondModal(true);
    setDeleteId(data.id);
  };

  const handleClose = () => {
    reset();
    setOpenModal(false);
  };
  useEffect(() => {
    getAllAdmins();
  }, []);
  const onSubmit = (data) => {
    createAdmins(data);
  };

  const getAllAdmins = async () => {
    setLoading(true);
    try {
      const response = await axiosRequestAuth("get", "v2/admin");

      setAdmins(response?.data?.data?.admins);
      setLoading(false);
      // toast.success(response.data.message);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong. Please try again!");
    }
  };

  const deleteAdmin = async (id) => {
    setLoading(true);
    try {
      const response = await axiosRequestAuth("post", `v2/admin/remove`, {
        user_account: deleteId,
      });

      toast.success(response.data.message);

      getAllAdmins();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  const createAdmins = async (data) => {
    setLoading(true);
    setOpenModal(false);

    try {
      const response = await axiosRequestAuth("post", "v2/admin", {
        user_account: data.user_account,
      });
      reset();

      //   setAdmins(response.data.data);
      getAllAdmins();
      setLoading(false);
      toast.success(response.data.message);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
      reset();
    }
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const columns = [
    // { key: "id", label: "ID", dataType: "number" },
    { key: "user_account", label: "Wallet Address", dataType: "text" },
    // { key: "thumbnail", label: "Thumbnail", dataType: "image" },
    { key: "createdAt", label: "Created At", dataType: "date" },
    { key: "updatedAt", label: "Updated At", dataType: "date" },
  ];
  const modalContent = (
    <Box p={2}>
      <Typography variant="h6" sx={{ color: txtcolor }}>
        Are you sure you want to delete?
      </Typography>
      {/* Any additional content you want in the modal */}
    </Box>
  );
  return (
    <BackgroundWrapper>
      <Grid container spacing={1} pl={5} pr={10}>
        <Grid item lg={2}>
          {" "}
          <Box sx={{ display: "flex", justifyContent: "start", pt: 3, ml: 1 }}>
            <Button
              size="large"
              sx={{ color: txtcolor }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon fontSize="large" sx={{ color: txtcolor }} />
            </Button>
          </Box>
        </Grid>
        <Grid item lg={8}>
          <Box sx={{ display: "flex", justifyContent: "center", pt: 2, pb: 4 }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                color: "white",
                textAlign: "center",
                py: 2,
              }}
            >{`Admins Management`}</Typography>
          </Box>
        </Grid>
        <Grid item lg={2}>
          <Box sx={{ display: "flex", justifyContent: "center", pt: 3 }}>
            <Button
              size="large"
              variant="contained"
              sx={{
                bgcolor: "#af22c8",
                "&:hover": {
                  bgcolor: "#62136f", // Adjust the darkness level as needed
                },
              }}
              onClick={handelModalOpen}
            >
              {/* <AddIcon fontSize="large" sx={{ color: txtcolor }} /> */}
              Add Admin
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid xl={2} lg={2} md={2} sm={0} xs={0} />
        <Grid xl={8} lg={8} md={8} sm={12} xs={12}>
          <>
            {!loading ? (
              <CustomTable
                data={admins}
                editable={false}
                columns={columns}
                onEditClick={handleEditClick}
                onDeleteClick={handleDeleteClick}
                showVisibleIcon={true}
                actions={true}
              />
            ) : (
              <CustomLoader width="20%" text={"Please wait ..."} />
            )}
          </>
        </Grid>
        <Grid xl={2} lg={2} md={2} sm={0} xs={0} />
      </Grid>
      <Modal
        componentsProps={{
          backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0.8)" } },
        }}
        open={openModal}
        onClose={handleClose}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            direction: "column",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            pt: 6,
            flexDirection: "column",
          }}
        >
          <Paper
            elevation={2}
            sx={{
              bgcolor: "black",
              border: "solid 2px #af22c8",
              borderRadius: 4,
              color: "white",
              p: 4,
              width: "700px",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
              <Box
                sx={{
                  justifyContent: "center",

                  // py: 2,
                  pb: 2,
                  // background: "grey",
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      sx={{ py: 0 }}
                    >
                      <Box />
                      <Box display={"flex"} textAlign={"center"} sx={{ pt: 1 }}>
                        <Typography variant="h6" sx={{ color: txtcolor }}>
                          Add Admin
                        </Typography>
                      </Box>
                      <Box justifyContent={"end"} sx={{ mt: -6, mr: -2 }}>
                        <IconButton
                          sx={{
                            bgcolor: "none",
                            color: "white",
                            border: "2px solid #af22c8",
                            borderRadius: "8px", // Adjust the value to make it more square-ish
                            padding: "2px", // Adjust padding as needed
                          }}
                          onClick={handleClose}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={1} justifyContent={"center"}></Grid>

                  <Grid item lg={10} justifyContent={"center"}>
                    <Box sx={{ py: 2 }}>
                      <CustomInput
                        name="user_account"
                        register={register}
                        errors={errors}
                        bgcolor={bgcolor}
                        txtcolor={txtcolor}
                        placeholder={"Wallet Address"}
                        isFullWidth={true}
                      />
                    </Box>
                  </Grid>

                  <Grid item lg={1} justifyContent={"center"}></Grid>
                </Grid>
                <Grid container spacing={2} pt={2}>
                  <Grid item lg={4} justifyContent={"center"}></Grid>
                  <Grid item lg={4} justifyContent={"center"}>
                    <Box display={"flex"} justifyContent={"center"}>
                      <Button
                        fullWidth
                        variant="contained"
                        type="submit"
                        sx={{
                          width: "100px",
                          bgcolor: "#af22c8",
                          "&:hover": {
                            bgcolor: "#62136f", // Adjust the darkness level as needed
                          },
                        }}
                      >
                        Add
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item lg={4} justifyContent={"center"}></Grid>
                </Grid>
              </Box>
            </form>
          </Paper>
        </Box>
      </Modal>

      <CustomModal
        open={openSecondModal}
        setOpenModal={setOpenSecondModal}
        content={modalContent}
        onConfirm={deleteAdmin}
      />
    </BackgroundWrapper>
  );
}

export default Admins;
