import React from "react";
import { Box, Grid, Typography, Paper, useMediaQuery } from "@mui/material";
import dummyData from "./dummydata2";
import styles from "./MidBanner.module.css";
const MidBanner = () => {
  const isBelow1024px = useMediaQuery("(max-width: 1024px)");
  const isBelow1824px = useMediaQuery("(max-width: 1824px)");
  return (
    <Box className={styles.gradient}>
      <Grid container sx={{ mb: 4 }}>
        <Grid item xs={12}>
          <Paper
            className="gradient-bg"
            sx={{
              width: "100%",
              height: isBelow1024px ? 150 : 200,
              background: "none",
              borderRadius: "0",
            }}
            elevation={4}
          >
            <Box
              pt={2}
              display={"flex"}
              textAlign={"center"}
              justifyContent={"center"}
            >
              <Typography
                variant={isBelow1824px ? "h5" : "h4"}
                sx={{
                  color: "white",
                  fontWeight: 600,
                  letterSpacing: "1px",
                  fontSize: isBelow1024px ? 9 : isBelow1824px ? 18 : 24,
                  fontFamily: "goodTime",
                }}
              >
                {
                  "Connected and trusted by the ecosystem to break boundaries by linking chains"
                }
              </Typography>
            </Box>
            <Grid container spacing={4} sx={{ p: 2 }}>
              {dummyData.map((image) => (
                <>
                  <Grid item lg={4} sm={4} xs={4}>
                    <Box sx={{ p: 2, justifyContent: "center" }}>
                      <Box sx={{ textAlign: "center" }}>
                        <img
                          src={image.url}
                          alt={image.title}
                          height={isBelow1024px ? 30 : 60}
                          // className="pointer-on-hover"
                        />
                      </Box>
                    </Box>
                  </Grid>
                </>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MidBanner;
