import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/shared/customTable/customTable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import BackgroundWrapper from "../../../components/layout/Shared/BackgroundWrapper/BackgroundWrapper";
import { Grid, Box, Typography, Button } from "@mui/material";
import { axiosRequestAuth } from "../../../services/api/api";
import toast from "react-hot-toast";

import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CustomModal from "../../../components/shared/customModal/customModal";
import CustomLoader from "../../../components/shared/customLoader/customLoader";

function BulkMintDetail() {
  const [loading, setLoading] = useState(false);
  const [mintDetails, setMintDetails] = useState();
  const [deleteId, setDeleteId] = useState();
  const [openModal, setOpenModal] = useState(false);
  // Example user data
  const navigate = useNavigate();

  const txtcolor = "#f1f1f1";
  // Handle the edit action
  const handleEditClick = (data) => {
    // Handle edit action for the selected user
    navigate(`/admin/categories/${data.id}`);
  };
  const handleDeleteClick = (data) => {
    // Handle edit action for the selected user

    // deleteFeatured(data.id);
    setOpenModal(true);
    setDeleteId(data.id);
  };

  useEffect(() => {
    getAllMintingReq();
  }, []);

  const getAllMintingReq = async () => {
    setLoading(true);
    try {
      const response = await axiosRequestAuth("get", "v2/bulk-mint");

      setMintDetails(response?.data?.data?.queueItems);
      setLoading(false);
      // toast.success(response.data.message);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  const deleteCollections = async (id) => {
    setLoading(true);
    try {
      const response = await axiosRequestAuth(
        "post",
        `collection/delete/${deleteId}`
      );

      toast.success(response.data.message);

      getAllMintingReq();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  const columns = [
    { key: "bucket_name", label: "Bucket Name", dataType: "text" },
    { key: "bucket_url", label: "Bucket URL", dataType: "text" },

    { key: "taxon", label: "Taxon", dataType: "text" },
    { key: "total_json_files", label: "Total", dataType: "text" },
    { key: "total_minted", label: "Minted", dataType: "text" },
    { key: "total_failed", label: "Failed", dataType: "text" },
    { key: "status_message", label: "Status", dataType: "text" },
    { key: "createdAt", label: "Added On", dataType: "date" },
  ];

  const modalContent = (
    <Box p={2}>
      <Typography variant="h6" sx={{ color: txtcolor }}>
        Are you sure you want to delete?
      </Typography>
      {/* Any additional content you want in the modal */}
    </Box>
  );

  return (
    <BackgroundWrapper>
      <Grid container spacing={1} pl={5} pr={10}>
        <Grid item md={2} sm={4}>
          {" "}
          <Box sx={{ display: "flex", justifyContent: "start", pt: 3, ml: 1 }}>
            <Button
              size="large"
              sx={{ color: txtcolor }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon fontSize="large" sx={{ color: txtcolor }} />
            </Button>
          </Box>
        </Grid>
        <Grid item md={8} sm={4}>
          <Box sx={{ display: "flex", justifyContent: "center", pt: 2, pb: 4 }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                color: "white",
                textAlign: "center",
                py: 2,
              }}
            >{`Bulk Mint`}</Typography>
          </Box>
        </Grid>
        <Grid item md={2} sm={4}>
          <Box sx={{ display: "flex", justifyContent: "center", pt: 3 }}>
            <Button
              size="large"
              variant="contained"
              sx={{
                bgcolor: "#af22c8",
                "&:hover": {
                  bgcolor: "#62136f", // Adjust the darkness level as needed
                },
              }}
              onClick={() => navigate("/minting-bulk")}
            >
              {/* <AddIcon fontSize="large" sx={{ color: txtcolor }} /> */}
              Add New
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid xl={2} lg={1} md={1} sm={0} xs={0} />
        <Grid xl={8} lg={10} md={10} sm={12} xs={12}>
          <>
            {!loading ? (
              <CustomTable
                data={mintDetails}
                editable={false}
                columns={columns}
                onEditClick={handleEditClick}
                onDeleteClick={handleDeleteClick}
                actions={false}
              />
            ) : (
              <CustomLoader width="20%" text={"Please wait ..."} />
            )}
          </>
        </Grid>
        <Grid xl={2} lg={1} md={1} sm={0} xs={0} />
      </Grid>
      <CustomModal
        open={openModal}
        setOpenModal={setOpenModal}
        content={modalContent}
        onConfirm={deleteCollections}
      />
    </BackgroundWrapper>
  );
}

export default BulkMintDetail;
