import React, { useEffect } from "react";
import { Typography, Container, Link, Box } from "@mui/material";

const Terms = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <Box sx={{ color: "white", pt: 6 }}>
      <Container maxWidth="md" sx={{ color: "white" }}>
        <Typography variant="h4" gutterBottom>
          Lux Lions Terms and Conditions
        </Typography>
        <Typography variant="body1" paragraph>
          Lux Lions NFTs are a collection of digital artworks running on the XRP
          Ledger. This website runs as an interface built to help participants
          purchase, collect and exchange these digital collectibles. Users are
          entirely responsible for the sole safety and management of their own
          private XRP Ledger and validating all transactions generated by this
          website before approving them. As Lux Lions runs on the XRP Ledger,
          there is no ability to undo, reverse, or restore any transaction.
          Furthermore, use of this website is at each user’s own risk and Lux
          Lions is not responsible or liable for any incorrect or failed
          transactions.
        </Typography>
        <Typography variant="body1" paragraph>
          To utilize this website and any related tools it may provide, users
          agree not to hold Lux Lions responsible for any and all potential
          errors, issues, and/or failures regardless of the cause or source of
          those errors and access complete sole responsibility for any and all
          transactions. This website and its connected services are without
          warranty of any kind, purchases are completed “as is”. By utilizing{" "}
          <Link href="https://luxlionsnft.com/">https://luxlionsnft.com/</Link>{" "}
          each user is accepting sole responsibility for any and all
          transactions Lux Lions digital collectibles.
        </Typography>
        <Typography variant="body1" paragraph>
          Ownership of the NFT is mediated entirely by the XRP Ledger. Once
          purchased, you own the Lux Lion NFT. Each Lux Lion is an NFT on the
          XRP Ledger. When a user purchases an NFT, that user has complete
          ownership of the NFT with no interference from us. With purchase of a
          Lux Lion NFT you are granted a royalty-free license to use, copy, and
          display the purchased Lux Lion NFT. This includes any extensions that
          you choose to create or use, solely for your own personal use; as part
          of a marketplace that permits the purchase and sale of your Lux Lion
          NFT, verifying each Lux Lion owner’s rights to display the art for
          their owned Lux Lion. For the sake of clarity, nothing is deemed to
          restrict you from owning or operating a personal or commercial
          marketplace that permits the use and sale of your specifically owned
          Lux Lion.
        </Typography>
        <Typography variant="body1" paragraph>
          Make sure to check these terms and conditions periodically for
          changes. We may make changes to the terms and conditions at our
          discretion. Changes to the terms and conditions will apply immediately
          at the time they are made, and your continued access to or use after
          the terms and conditions have been updated will constitute your
          binding acceptance of all updates. If you do not agree to any revised
          terms and conditions, you may not access or use the site.
        </Typography>
      </Container>
    </Box>
  );
};

export default Terms;
