import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  TextField,
  Grid,
  MenuItem,
  Typography,
  CircularProgress,
} from "@mui/material";
// import "./Mint.css";
import styles from "./Mint.module.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import styles from "./Mint.module.css";
import { useNavigate } from "react-router-dom";
import BackgroundWrapper from "../../layout/Shared/BackgroundWrapper/BackgroundWrapper";
import { toast } from "react-hot-toast";
import { axiosRequestAuth } from "../../../services/api/api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomLoader from "../../shared/customLoader/customLoader";
// import CustomInput from "../../components/shared/customInput/customInput";

let flags = [
  { key: 8, value: "Transferable" },
  { key: 9, value: "Transferable, Burnable" },
];

const SingleMintingForm = () => {
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const bgcolor = "rgba(104, 103, 103, 0.5)";
  const txtcolor = "#f1f1f1";
  const navigate = useNavigate();

  const [formDataImage, setFormDataImage] = useState(null);
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");

  const [formDataJson, setFormDataJson] = useState(null);

  const [isJson, setIsJson] = useState("Yes");
  const [collection, setCollection] = useState([]);
  const [collectionId, setCollectionTaxon] = useState();
  const [flagsState, setFlagsState] = useState(8);
  const [transferFee, setTransferFee] = useState(0);
  const [price, setPrice] = useState();

  const [selectedImage, setSelectedImage] = useState(null); // setState for selected image

  const [formValid, setFormValid] = useState(false);

  const checkFormValidity = () => {
    console.log(isJson, "isJson");
    if (isJson === "Yes") {
      if (
        formDataImage &&
        collectionId &&
        flagsState &&
        formDataJson &&
        transferFee > 0
      ) {
        setFormValid(true);
      } else {
        setFormValid(false);
      }
    } else {
      if (
        formDataImage &&
        name &&
        collectionId &&
        flagsState &&
        transferFee > 0
      ) {
        setFormValid(true);
      } else {
        setFormValid(false);
      }
    }
  };

  const resetFormFields = () => {
    setFormDataImage(null);
    setFormDataJson(null);
    setCollectionTaxon(null);
    setFlagsState(8);
    setTransferFee(0);
    setSelectedImage(null);
    setName("");
    setDescription("");
    setIsJson("Yes");

    // Clear the JSON input file
    const jsonInput = document.getElementById("json-input");
    const flagInput = document.getElementById("flag-input");
    const feeInput = document.getElementById("fee-input");
    if (jsonInput || flagInput || feeInput) {
      jsonInput.value = null;
      flagInput.value = null;
      feeInput.value = null;
    }
  };

  const handleImageClick = () => {
    // Programmatically trigger the file input when the box is clicked
    document.getElementById("image-upload-input").click();
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const file = e.target.files[0];
      setFormDataJson(file);
    } else {
      setFormDataJson(null);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setFormDataImage(file);

      // You can perform additional checks here, e.g., file type, size, etc.

      // Read the selected file as a data URL and set it as the selected image
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  let jsonTypeOptions = [
    { id: "Yes", name: "Yes" },
    { id: "No", name: "No" },
  ];
  const onSubmit = () => {
    let user_account = localStorage.getItem("accnum");
    let formData = new FormData();
    formData.append("Files[img]", formDataImage);
    formData.append("Files[json]", formDataJson);
    formData.append("collection_id", collectionId);
    formData.append("user_account", user_account);
    formData.append("flag", flagsState);
    formData.append("transferFee", transferFee);
    formData.append("price", price);
    formData.append("requiredJSON", isJson);
    name && formData.append("name", name);
    description && formData.append("discription", description);

    MintNft(formData);
  };

  async function MintNft(formData) {
    setBtnLoading(true);
    toast.success("Minting in process, please wait!");

    try {
      const response = await axiosRequestAuth("post", "v2/mint", formData);
      setBtnLoading(false);
      toast.success(response?.data?.message);
      resetFormFields();
    } catch (error) {
      setBtnLoading(false);
      // toast.error(error?.response?.data?.message);
    }
  }

  useEffect(() => {
    getAllCollection();
  }, []);
  useEffect(() => {
    checkFormValidity(); // Check form validity when the component mounts
  }, [
    formDataImage,
    formDataJson,
    collectionId,
    flagsState,
    transferFee,
    description,
    name,
    isJson,
    price,
  ]);

  async function getAllCollection() {
    setLoading(true);

    try {
      const response = await axiosRequestAuth("get", "v2/collection/drop-down");
      setLoading(false);
      console.log(response.data, "response");
      setCollection(response?.data?.data);
    } catch (error) {
      setLoading(false);
    }
  }

  const handleCollectionChange = (event) => {
    console.log(event.target.value, " event.target.value");
    const selectedTaxonId = event.target.value;
    setCollectionTaxon(selectedTaxonId);
  };

  const handleJSONChange = (event) => {
    const newsJson = event.target.value;
    setIsJson(newsJson);
  };

  const handleDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleName = (event) => {
    setName(event.target.value);
  };

  const handleFlagChange = (event) => {
    const flagsVal = event.target.value;
    setFlagsState(flagsVal);
  };

  const handleTransferFee = (e) => {
    const inputValue = e.target.value;

    setTransferFee(inputValue);
  };
  const handlePrice = (e) => {
    const inputValue = e.target.value;

    setPrice(inputValue);
  };

  return (
    <BackgroundWrapper>
      <>
        <Box>
          <Grid container spacing={2}>
            <Grid item lg={2}>
              {" "}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  pt: 1,
                  ml: 6,
                }}
              >
                <Button
                  size="large"
                  sx={{ color: txtcolor }}
                  onClick={() => navigate(-1)}
                >
                  <ArrowBackIcon fontSize="large" sx={{ color: txtcolor }} />
                </Button>
              </Box>
            </Grid>
            <Grid item lg={8}>
              <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
                <Typography
                  variant="h4"
                  sx={{ color: txtcolor, fontWeight: 600 }}
                >
                  Single Minting
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={2}></Grid>
          </Grid>
          {btnLoading ? (
            <Box
              height={"90vh"}
              sx={{
                justifyContent: "center",
                display: "flex",
                textAlign: "center",
              }}
            >
              {/* <Typography>Minting in process</Typography> */}
              <CustomLoader width="60%" text={"Please wait ..."} />
            </Box>
          ) : (
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                direction: "row",
                justifyContent: "center",
                pt: 2,
              }}
            >
              <Grid
                item
                xl={4}
                lg={4}
                sm={4}
                sx={{
                  p: 2,
                }}
              />

              <Grid
                item
                xl={4}
                lg={4}
                sm={4}
                sx={{
                  p: 2,
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                  id="image-upload-input"
                />
                <div
                  onClick={handleImageClick}
                  style={{
                    maxWidth: "100%",
                    minWidth: "250px",
                    height: "240px",
                    borderRadius: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    background: bgcolor,
                  }}
                >
                  {selectedImage ? (
                    <img
                      src={selectedImage}
                      alt="Selected"
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  ) : (
                    <CloudUploadIcon style={{ fontSize: 48 }} />
                  )}
                </div>
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    // background: "grey",
                    borderRadius: 1,
                  }}
                >
                  {isJson === "Yes" ? (
                    <>
                      <div style={{ color: "#fff" }}>Upload Json:</div>
                      <input
                        required
                        type="file"
                        accept=".json"
                        placeholder="Choose json"
                        onChange={handleFileChange}
                        className={styles.customFileInput}
                        style={{ borderRadius: 4 }}
                        id="json-input"
                      />
                    </>
                  ) : (
                    <Grid item lg={12} sx={{}}>
                      <Box sx={{}}>
                        <Typography
                          variant="body1"
                          sx={{ color: txtcolor, fontWeight: 500 }}
                        >
                          Name
                        </Typography>
                        <TextField
                          required
                          size="small"
                          fullWidth
                          value={name}
                          onChange={handleName}
                          placeholder={"Name"}
                          variant="outlined"
                          InputProps={{ inputProps: { min: 1 } }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: bgcolor, // Background color when not active
                              "&:hover fieldset": {
                                borderColor: "purple", // Border color on hover
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "purple", // Border color on focus
                              },
                            },
                            "& .MuiInputBase-input": {
                              color: txtcolor, // Text color
                            },
                          }}
                        ></TextField>
                      </Box>
                    </Grid>
                  )}
                </Box>

                <Box sx={{ pt: 2 }}>
                  <Typography
                    variant="body1"
                    sx={{ color: txtcolor, fontWeight: 500 }}
                  >
                    Select Collection*
                  </Typography>
                  <TextField
                    select
                    size="small"
                    fullWidth
                    onChange={handleCollectionChange}
                    value={collectionId || ""}
                    id="outlined-basic"
                    placeholder={"Select Collection"}
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: bgcolor, // Background color when not active
                        "&:hover fieldset": {
                          borderColor: "purple", // Border color on hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "purple", // Border color on focus
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: txtcolor, // Text color
                      },
                    }}
                  >
                    {collection.map((collection) => (
                      <MenuItem key={collection.id} value={collection.id}>
                        {collection.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Grid
                  container
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  {/* <Grid item lg={6} sx={{}}>
                    <Box sx={{ pt: 2 }}>
                      <Typography
                        variant="body1"
                        sx={{ color: txtcolor, fontWeight: 500 }}
                      >
                        Price*
                      </Typography>
                      <TextField
                        required
                        size="small"
                        fullWidth
                        type="number"
                        onChange={handlePrice}
                        placeholder={"Price"}
                        variant="outlined"
                        InputProps={{ inputProps: { min: 1 } }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            backgroundColor: bgcolor, // Background color when not active
                            "&:hover fieldset": {
                              borderColor: "purple", // Border color on hover
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "purple", // Border color on focus
                            },
                          },
                          "& .MuiInputBase-input": {
                            color: txtcolor, // Text color
                          },
                        }}
                      ></TextField>
                    </Box>
                  </Grid> */}
                  <Grid item lg={6} sx={{ pt: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{ color: txtcolor, fontWeight: 500 }}
                    >
                      Transfer Fee*
                    </Typography>
                    <TextField
                      id="fee-input"
                      required
                      size="small"
                      fullWidth
                      type="number"
                      onChange={handleTransferFee}
                      placeholder={"Select Fees"}
                      variant="outlined"
                      InputProps={{ inputProps: { min: 1 } }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: bgcolor, // Background color when not active
                          "&:hover fieldset": {
                            borderColor: "purple", // Border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "purple", // Border color on focus
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: txtcolor, // Text color
                        },
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} sx={{ pt: 2, pl: 1 }}>
                    <Typography variant="body1" sx={{ color: txtcolor }}>
                      Required JSON?
                    </Typography>
                    <TextField
                      id="flag-input"
                      select
                      size="small"
                      fullWidth
                      value={isJson}
                      defaultValue={"Yes"}
                      onChange={handleJSONChange}
                      placeholder="Select Flag"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: bgcolor,
                          "&:hover fieldset": {
                            borderColor: "purple",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "purple",
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: txtcolor,
                        },
                      }}
                    >
                      {jsonTypeOptions.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>

                <Grid
                  container
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <Grid item lg={12} sx={{ pt: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{ color: txtcolor, fontWeight: 500 }}
                    >
                      Set Flag
                    </Typography>
                    <TextField
                      id="flag-input"
                      select
                      size="small"
                      fullWidth
                      onChange={handleFlagChange}
                      // id="outlined-basic"
                      placeholder={"Select Flag"}
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: bgcolor, // Background color when not active
                          "&:hover fieldset": {
                            borderColor: "purple", // Border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "purple", // Border color on focus
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: txtcolor, // Text color
                        },
                      }}
                    >
                      {flags.map((item) => (
                        <MenuItem key={item.key} value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {/* <Grid item lg={6} sx={{ pt: 2, pl: 1 }}>
                    <Typography
                      variant="body1"
                      sx={{ color: txtcolor, fontWeight: 500 }}
                    >
                      Transfer Fee*
                    </Typography>
                    <TextField
                      id="fee-input"
                      required
                      size="small"
                      fullWidth
                      type="number"
                      onChange={handleTransferFee}
                      placeholder={"Select Fees"}
                      variant="outlined"
                      InputProps={{ inputProps: { min: 1 } }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: bgcolor, // Background color when not active
                          "&:hover fieldset": {
                            borderColor: "purple", // Border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "purple", // Border color on focus
                          },
                        },
                        "& .MuiInputBase-input": {
                          color: txtcolor, // Text color
                        },
                      }}
                    ></TextField>
                  </Grid> */}
                </Grid>
                <Grid
                  container
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  {isJson === "No" && (
                    <Grid item lg={12} sx={{}}>
                      <Box sx={{ pt: 2 }}>
                        <Typography
                          variant="body1"
                          sx={{ color: txtcolor, fontWeight: 500 }}
                        >
                          Description
                        </Typography>
                        <TextField
                          required
                          size="small"
                          fullWidth
                          multiline
                          value={description}
                          onChange={handleDescription}
                          placeholder={"Description"}
                          variant="outlined"
                          InputProps={{ inputProps: { min: 1 } }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: bgcolor, // Background color when not active
                              "&:hover fieldset": {
                                borderColor: "purple", // Border color on hover
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "purple", // Border color on focus
                              },
                            },
                            "& .MuiInputBase-input": {
                              color: txtcolor, // Text color
                            },
                          }}
                        ></TextField>
                      </Box>
                    </Grid>
                  )}
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    pt: 2,
                  }}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      background: "#EFA61C",
                      color: "white",

                      "&:hover": {
                        backgroundColor: "#b2790d",
                        color: "f1f1f1",
                        "& .MuiSvgIcon-root": {
                          color: "black", // Change the icon color on hover
                        },
                      },
                      "&:active": {
                        backgroundColor: "#e3ab12",
                        color: "f1f1f1",
                        "& .MuiSvgIcon-root": {
                          color: "black", // Change the icon color on click
                        },
                      },
                      "&:disabled": {
                        backgroundColor: "#e3e3e3",
                        cursor: "not-allowed",
                      },
                    }}
                    onClick={onSubmit}
                    disabled={!formValid}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {btnLoading ? (
                        <CircularProgress size={26} sx={{ color: "white" }} />
                      ) : (
                        "Mint"
                      )}
                    </Typography>
                  </Button>
                </Box>
                {/* </Box> */}
              </Grid>

              <Grid
                item
                lg={4}
                xl={4}
                sm={4}
                sx={{
                  p: 2,
                }}
              />
            </Grid>
          )}
        </Box>
      </>
    </BackgroundWrapper>
  );
};

export default SingleMintingForm;
