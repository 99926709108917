import React, { useEffect, useState } from "react";
import { Button, Typography, Tooltip, Box } from "@mui/material";
import { toast } from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  logout,
} from "../../../services/redux/LoginReducers/LoginReducers";
import { XummPkce } from "xumm-oauth2-pkce";
import LogoutIcon from "@mui/icons-material/Logout";
import { axiosRequest, axiosRequestAuth } from "../../../services/api/api";
import {
  resetCountNFT,
  setApiTimer,
  setCountNFT,
  setIsAdmin,
  setToken,
  setUserId,
} from "../../../services/redux/UserNFTCountReducer/UserNFTCountReducer";
import CustomLoader from "../../shared/customLoader/customLoader";
import { socket } from "../../../App";

const XUMM_API_KEY = process.env.REACT_APP_XUMM_API_KEY;
const xumm = new XummPkce(XUMM_API_KEY);
const loginURL = "v2/user/login";

const Login = ({ drawer }) => {
  const time = localStorage.getItem("expire");
  const location = useLocation();
  const userWallet = localStorage.getItem("accnum");

  const currentDate = new Date(); // Assuming currentDate is in milliseconds
  var storedDate = new Date(time);
  const [loginState, setLoginState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noOfTransfer, setNoOfTransfer] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pathname } = location;
  const { nftCount, userApi } = useSelector((state) => state.userNft);
  const { acceptTransferRes } = useSelector((state) => state.mynft);

  async function XummLogin() {
    if (xumm) {
      try {
        const response = await xumm.authorize();

        if (response) {
          LoginAPI(response);
          dispatch(login(response));
        }
      } catch (error) {
        console.error("Error authorizing user:", error);
      }
    }
  }

  async function LoginAPI(XummResponse) {
    setLoading(true);
    try {
      const response = await axiosRequest("post", loginURL, {
        user_account: XummResponse.me.account,
      });

      if (response) {
        dispatch(setIsAdmin(response?.data?.data?.isAdmin));
        dispatch(setUserId(response?.data?.data?.existingUser?.id));
        dispatch(setToken(XummResponse.jwt));
        dispatch(setApiTimer(false));

        userNFTs(XummResponse.me.account);
        transferCount();
        localStorage.setItem("apiKey", XummResponse.sdk.Meta.apiKey);
        localStorage.setItem("token", XummResponse.jwt);
        localStorage.setItem("accnum", XummResponse.me.account);
        localStorage.setItem("isAdmin", response?.data?.data?.isAdmin);
      }
      setLoading(false);

      const currentDate = new Date(); // Get current date and time
      const next23Hours = new Date(currentDate.getTime() + 23 * 60 * 60 * 1000); // Add 23 hours in milliseconds
      toast.success(response.data.message);
      localStorage.setItem("expire", next23Hours);

      // setTimeout(function () {
      window.location.reload();
      // }, 2000);
    } catch (error) {
      setLoading(false);
      localStorage.clear();
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong. Please try again!"
      );

      console.error(error);
    }
  }

  async function userNFTs() {
    try {
      const response = await axiosRequestAuth("get", "v2/user/nfts-count", {});
      if (response) {
        dispatch(setApiTimer(response.data.data.synced));
        dispatch(setCountNFT(response.data.data.total_nfts));
      }
    } catch (error) {}
  }

  async function transferCount() {
    try {
      const response = await axiosRequestAuth("get", "v2/transfer/count", {});
      if (response) {
        setNoOfTransfer(response.data?.data?.transfers_count);
      }
    } catch (error) {}
  }

  useEffect(() => {
    let intervalId;

    if (!userApi) {
      intervalId = setInterval(() => {
        userNFTs();
      }, 10000); // Execute every 10 seconds
    }

    return () => clearInterval(intervalId); // Clean up the interval on unmount or if userApi becomes false
  }, [userApi]);

  useEffect(() => {
    if (time && storedDate < currentDate) {
      toast.error("Session expire, Please Login again!");

      // Perform logout actions here, e.g., clear authentication, redirect to login page, etc.

      setTimeout(() => {
        Xummlogout();
        // Perform logout actions here, e.g., clear authentication, redirect to login page, etc.
      }, 4000);
    }
  }, [location]); // Dependency array including 'token'

  async function Xummlogout() {
    localStorage.clear();
    xumm.logout();
    dispatch(logout());
    navigate("/");
    setLoginState(false);
    dispatch(resetCountNFT());
    dispatch(setIsAdmin(false));
    window.location.reload();
  }
  useEffect(() => {
    if (localStorage.getItem("token")) {
      setLoginState(true);
      transferCount();
    } else {
      setLoginState(false);
    }
  }, [acceptTransferRes]);

  useEffect(() => {
    socket.on("new_transfer_offer", (res) => {
      console.log(res, "New transfer offer received");
      setTimeout(() => {
        transferCount();
        // Perform logout actions here, e.g., clear authentication, redirect to login page, etc.
      }, 3000);
    });

    // Clean up event listener
    return () => {
      socket.off("new_transfer_offer");
    };
  }, [socket]);

  useEffect(() => {}, [noOfTransfer]);

  return (
    <>
      <div>
        {" "}
        {loginState === false ? (
          <Button
            onClick={XummLogin}
            style={{ width: "150px" }}
            sx={{
              background: "white",
              borderRadius: 2,
              color: "black",
              width: "100%",
              "&:hover": {
                backgroundColor: "#0000",
                border: "2px solid #BF40BF",
                color: "white",
                "& .MuiSvgIcon-root": {
                  color: "white", // Change the icon color on hover
                },
              },
              "&:active": {
                backgroundColor: "#BF40BF",
                color: "white",
                "& .MuiSvgIcon-root": {
                  color: "white", // Change the icon color on click
                },
              },
            }}
          >
            <Typography sx={{ fontSize: 16 }}>{"Connect"}</Typography>
          </Button>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: drawer ? "row" : "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            {noOfTransfer > 0 && (
              <Button
                variant="contained"
                size="small"
                // You may want to define this function if you need specific logic for making offers
                sx={{
                  width: "100px",
                  textTransform: "none",
                  bgcolor: "#BF40BF",
                  color: "white",
                  borderRadius: "30px",
                  fontFamily: "goodTime",
                  "&:hover": { bgcolor: "#BF40BF" },
                  whiteSpace: "nowrap",
                }}
                onClick={() => {
                  if (pathname.includes("nfts/transfer")) {
                    window.location.reload();
                  }
                  navigate("/nfts/transfer");
                }}
              >
                {noOfTransfer} Task
              </Button>
            )}
            <Tooltip title={`${userWallet}`} sx={{ whiteSpace: "nowrap" }}>
              <Button
                onClick={Xummlogout}
                style={{ width: "150px" }}
                sx={{
                  background: "#0000",
                  borderRadius: 2,
                  border: "2px solid #BF40BF",
                  color: "white",
                  width: "100%",
                  "&:hover": {
                    // backgroundColor: "#330033 ",
                    boxShadow: "0 0 8px #BF40BF",
                    color: "white",
                    "& .MuiSvgIcon-root": {
                      color: "white", // Change the icon color on hover
                    },
                  },
                  "&:active": {
                    backgroundColor: "#4A235A",
                    color: "white",
                    "& .MuiSvgIcon-root": {
                      color: "white", // Change the icon color on click
                    },
                  },
                }}
              >
                <Typography sx={{ fontSize: 16, mr: 1 }}>{"Logout"}</Typography>
                <LogoutIcon
                // sx={{
                //   color: "white",
                //   "&:hover": {
                //     backgroundColor: "#36454F",
                //     color: "white",
                //   },
                //   "&:active": {
                //     backgroundColor: "#36454F",
                //     color: "white",
                //   },
                // }}
                />
              </Button>
            </Tooltip>
          </Box>
        )}
      </div>
      {loading && (
        <Box
          sx={{
            position: "fixed", // Or 'absolute' if you want it relative to its nearest positioned ancestor
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.8)", // Optional: adds a white translucent background
            zIndex: 1000, // Ensure it's above all other content
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              minHeight: "80vh", // Set minimum height to fill the entire viewport
            }}
          >
            <CustomLoader width={"60%"} text={"Please wait ..."} />{" "}
            {/* <Typography
              variant="h6"
              sx={{ color: "#C2A84A", fontFamily: "arial" }}
            >
              Please wait ...
            </Typography>{" "} */}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Login;
