import "./App.css";
import AllRoutes from "./routes/routes";
import Header from "./components/layout/Shared/Header/Header";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme"; // Import your custom theme
import Footer from "./components/layout/Shared/Footer/Footer";
import ToastConfig from "./utils/Toast/toastConfig";
import ScrollToTop from "./utils/ScrollToTop/ScrollToTop";
import { axiosRequestAuth, socketBaseURL } from "./services/api/api";
import { useDispatch, useSelector } from "react-redux";
import { setCountNFT } from "./services/redux/UserNFTCountReducer/UserNFTCountReducer";
import { useEffect } from "react";
import { useState } from "react";
import { io } from "socket.io-client";

export const socket = io(socketBaseURL, {
  // Configure CORS options
  transports: ["websocket"],
  withCredentials: false,
  extraHeaders: {
    "Access-Control-Allow-Origin": socketBaseURL, // Replace with your frontend origin
  },
});
console.log("testing")
function App() {
  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      console.log("socket connected");
      const userWallet = localStorage.getItem("accnum");
      socket.emit("new-connection", { user_wallet_address: userWallet });
    }

    function onDisconnect() {
      setIsConnected(false);
      console.log("socket connected failed");
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  }, []);

  // useEffect(() => {
  //   if (isConnected) {
  //     const userWallet = localStorage.getItem("accnum");
  //     socket.emit("new-connection", { user_wallet_address: userWallet });
  //   }
  // }, [isConnected]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App-background">
        <ScrollToTop />
        <Header />
        <AllRoutes />
        <Footer />
        <ToastConfig />
      </div>
    </ThemeProvider>
  );
}

export default App;
