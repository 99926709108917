import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Grid,
  MenuItem,
  Typography,
  IconButton,
} from "@mui/material";
// import "./Mint.css";

// import styles from "./Mint.module.css";
import { useNavigate } from "react-router-dom";

import { toast } from "react-hot-toast";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";

import { useSelector, useDispatch } from "react-redux";
import BackgroundWrapper from "../../../layout/Shared/BackgroundWrapper/BackgroundWrapper";
import CustomLoader from "../../../shared/customLoader/customLoader";
import {
  addWalletAddressesAPI,
  resetAddWalletAddressesAPI,
} from "../../../../services/redux/MyNftApiReducer/MyNftApiReducer";

// import CustomInput from "../../components/shared/customInput/customInput";

const AddWalletForm = () => {
  const bgcolor = "rgba(104, 103, 103, 0.5)";
  const txtcolor = "#f1f1f1";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fields, setFields] = useState([""]); // Initial state with an empty string
  const [fieldsOrderId, setFieldsOrderId] = useState([""]); // Initial state with an empty string
  const [destination, setDestination] = useState();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [orderErrors, setOrderErrors] = useState([]);
  const { addWalletAddressesData, loading: loader } = useSelector(
    (state) => state.mynft
  );
  const walletAddressRegex = /(?:^|\s)([a-zA-Z0-9]{30,})(?=\s|$)/;

  const validate = () => {
    const newErrors = {};

    // Check for empty fields in fieldsOrderId
    if (fieldsOrderId.some((field) => !field)) {
      newErrors.fieldsOrderId = "Order ID is required for all fields.";
    }

    // Check if all fieldsOrderId are numbers
    if (fieldsOrderId.some((field) => isNaN(field))) {
      newErrors.fieldsOrderIdNumbers = "All Order IDs must be numbers.";
    }

    // Check for duplicate fields in fieldsOrderId
    const fieldsOrderIdSet = new Set(fieldsOrderId);
    if (fieldsOrderIdSet.size !== fieldsOrderId.length) {
      newErrors.fieldsOrderIdUnique = "All Order IDs must be unique.";
    }

    // Check for empty fields in fields
    if (fields.some((field) => !field)) {
      newErrors.fields = "Wallet address is required for all fields.";
    }

    // // Check for duplicate fields in fields
    // const fieldsSet = new Set(fields);
    // if (fieldsSet.size !== fields.length) {
    //   newErrors.fieldsUnique = "All wallet addresses must be unique.";
    // }

    // Check for valid wallet addresses in fields
    if (fields.some((field) => !walletAddressRegex.test(field))) {
      newErrors.walletAddress = "One or more wallet addresses are invalid.";
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast.error(
        newErrors?.fieldsOrderId ||
          newErrors?.walletAddress ||
          newErrors?.fields ||
          newErrors?.fieldsOrderIdUnique ||
          newErrors?.fieldsOrderIdNumbers
      );
    }
    return Object.keys(newErrors).length === 0;
  };
  /////////////////////////////////////////////////////////
  const handleChange = (index, value) => {
    const newFields = [...fields];
    newFields[index] = value;
    setFields(newFields);
  };
  const handleChange2 = (index, value) => {
    const newFields = [...fieldsOrderId];
    newFields[index] = value;
    setFieldsOrderId(newFields);
  };

  const handleChangeDestination = (value) => {
    setDestination(value);
  };

  const handleAddField = () => {
    setFields([...fields, ""]);
    setFieldsOrderId([...fieldsOrderId, ""]);
  };

  const handleRemoveField = (index) => {
    if (fields?.length > 1) {
      const newFields = [...fields];
      newFields.splice(index, 1);
      setFields(newFields);
      const newFields2 = [...fieldsOrderId];
      newFields2.splice(index, 1);
      setFieldsOrderId(newFields2);
    }
  };

  function checkDuplicates(arr) {
    const uniqueSet = new Set(arr);
    return arr.length !== uniqueSet.size;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }

    let data = [];
    fields.map((item, index) =>
      data.push({
        wallet_adress: item,
        order_id: parseInt(fieldsOrderId[index]),
      })
    );
    // console.log("function submit calling", data);
    dispatch(addWalletAddressesAPI({ order_information: data }));
  };

  useEffect(() => {
    if (Object.keys(addWalletAddressesData?.data).length > 0) {
      if (addWalletAddressesData?.data?.status === true) {
        setOrderErrors([]);
        toast.success(addWalletAddressesData?.data?.message);
        dispatch(resetAddWalletAddressesAPI());
        navigate("/user-mints");
      } else if (addWalletAddressesData?.data?.status === false) {
        setOrderErrors(addWalletAddressesData?.data?.data);
        toast.error(addWalletAddressesData?.data?.message);
        dispatch(resetAddWalletAddressesAPI());
      }
    }
  }, [addWalletAddressesData?.data?.status]);

  useEffect(() => {
    setLoading(loader);
  }, [loader]);

  const checkOrderNumberExists = (data, orderNumber) => {
    const orderNumbers = data.map((item) => item?.order_id);

    return orderNumbers.some((item) => item === orderNumber);
  };

  const findIndex = (data, orderNumber) => {
    return data.findIndex((item) => item?.order_id === parseInt(orderNumber));
  };

  return (
    <BackgroundWrapper>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh", // Set minimum height to fill the entire viewport
          }}
        >
          <CustomLoader width={"60%"} text={"Please wait ..."} />
        </Box>
      ) : (
        <Box>
          <Grid container spacing={2}>
            <Grid item lg={2}>
              {" "}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  pt: 1,
                  ml: 1,
                  pl: 6,
                }}
              >
                <Button
                  size="large"
                  sx={{ color: txtcolor }}
                  onClick={() => navigate(-1)}
                >
                  <ArrowBackIcon fontSize="large" sx={{ color: txtcolor }} />
                </Button>
              </Box>
            </Grid>
            <Grid item lg={8}>
              <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
                <Typography
                  variant="h4"
                  sx={{ color: txtcolor, fontWeight: 600 }}
                >
                  Bulk Add Wallets
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={2}></Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              direction: "row",
              justifyContent: "center",
              pt: 2,
            }}
          >
            <Grid
              item
              xl={2}
              lg={2}
              sm={12}
              sx={{
                p: 2,
              }}
            />

            <Grid
              item
              xl={6}
              lg={8}
              sm={12}
              sx={{
                p: 2,
              }}
            >
              <Box>
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      maxHeight: "70vh",
                      overflowY: "auto",
                      pb: 2,
                    }}
                  >
                    <Grid item xs={3}>
                      <Box sx={{ pt: 2 }}>
                        <Typography sx={{ color: "white" }}>
                          Add Order Id
                        </Typography>
                      </Box>

                      <Box>
                        {fieldsOrderId.map((field, index) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              pb: 1,
                            }}
                          >
                            <TextField
                              required
                              // label={`Field ${index + 1}`}
                              size="small"
                              value={field}
                              onChange={(e) =>
                                handleChange2(index, e.target.value)
                              }
                              fullWidth
                              sx={{
                                border: checkOrderNumberExists(
                                  orderErrors,
                                  parseInt(field)
                                )
                                  ? "1px solid red"
                                  : !checkOrderNumberExists(
                                      orderErrors,
                                      parseInt(field)
                                    ) &&
                                    orderErrors?.length > 0 &&
                                    "1px solid transparent",
                                "& .MuiOutlinedInput-root": {
                                  backgroundColor: bgcolor, // Background color when not active
                                  "&:hover fieldset": {
                                    borderColor: "purple", // Border color on hover
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "purple", // Border color on focus
                                  },
                                },
                                "& .MuiInputBase-input": {
                                  color: txtcolor, // Text color
                                },
                              }}
                            />

                            {checkOrderNumberExists(
                              orderErrors,
                              parseInt(field)
                            ) && (
                              <Typography
                                sx={{
                                  color: "red",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  pt: 0.3,
                                }}
                              >
                                {
                                  orderErrors[findIndex(orderErrors, field)]
                                    ?.message
                                }
                              </Typography>
                            )}

                            {/* <IconButton
                              onClick={() => handleRemoveField(index)}
                            >
                              <DeleteIcon sx={{ color: "red" }} />
                            </IconButton> */}
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                    <Grid item xs={9}>
                      <Box sx={{ pt: 2 }}>
                        <Typography sx={{ color: "white" }}>
                          Add Wallet Address
                        </Typography>
                      </Box>

                      <Box>
                        {fields.map((field, index) => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              pb: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              <TextField
                                required
                                // label={`Field ${index + 1}`}
                                size="small"
                                value={field}
                                onChange={(e) =>
                                  handleChange(index, e.target.value)
                                }
                                fullWidth
                                sx={{
                                  border:
                                    orderErrors?.length > 0 &&
                                    "1px solid transparent",
                                  "& .MuiOutlinedInput-root": {
                                    backgroundColor: bgcolor, // Background color when not active
                                    "&:hover fieldset": {
                                      borderColor: "purple", // Border color on hover
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "purple", // Border color on focus
                                    },
                                  },
                                  "& .MuiInputBase-input": {
                                    color: txtcolor, // Text color
                                  },
                                }}
                              />
                              {checkOrderNumberExists(
                                orderErrors,
                                parseInt(fieldsOrderId[index])
                              ) &&
                                orderErrors?.length > 0 && (
                                  <Typography
                                    sx={{
                                      color: "transparent",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      pt: 0.3,
                                    }}
                                  >
                                    Order Id found
                                  </Typography>
                                )}
                            </Box>

                            <Box>
                              <IconButton
                                onClick={() => handleRemoveField(index)}
                              >
                                <DeleteIcon sx={{ color: "red" }} />
                              </IconButton>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                  <Box sx={{ display: "flex", mt: 1 }}>
                    <Box sx={{ mr: 2 }}>
                      <Button
                        variant="contained"
                        sx={{
                          width: "120px",
                          bgcolor: "#af22c8",
                          "&:hover": {
                            bgcolor: "#62136f", // Adjust the darkness level as needed
                          },
                        }}
                        onClick={handleAddField}
                      >
                        Add More
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        sx={{
                          width: "100px",
                          bgcolor: "#af22c8",
                          "&:hover": {
                            bgcolor: "#62136f", // Adjust the darkness level as needed
                          },
                        }}
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Grid>
            <Grid
              item
              lg={6}
              xl={2}
              sm={12}
              sx={{
                p: 2,
              }}
            />
          </Grid>
        </Box>
      )}
    </BackgroundWrapper>
  );
};

export default AddWalletForm;
