import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  offset: "",
};

const offsetSlice = createSlice({
  name: "offset",
  initialState,
  reducers: {
    setOffsetState(state, action) {
      state.offset = action.payload;
    },
    resetOffsetState(state, action) {
      state.offset = "";
    },
  },
});

export const { setOffsetState, resetOffsetState } = offsetSlice.actions;

export default offsetSlice.reducer;
