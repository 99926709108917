import React, { useEffect, useState } from "react";

import { Box, Grid, Typography, Button, Divider } from "@mui/material";

import NFTCard from "../../components/features/NFTCard/NFTCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { recentlyForSaleNft } from "../../services/redux/NftApiReducer/NftApiReducer";
import toast from "react-hot-toast";
import CustomLoader from "../../components/shared/customLoader/customLoader";
import {
  resetOffsetState,
  setOffsetState,
} from "../../services/redux/OffsetReducer/OffsetReducer";
import ScrollToTopOnMount from "../../utils/ScrollToTop/automaticScrollToTop/automaticScrollToTop";
import {
  resetAcceptTransferRes,
  resetapproveOfferNft,
  resetBuyDirectNftState,
  resetBuyOfferState,
  resetsellOfferCancelState,
  resetSellState,
  resetTransferNftState,
} from "../../services/redux/MyNftApiReducer/MyNftApiReducer";
const containerStyle = {
  position: "relative",
  width: "100%",
  minHeight: "100vh",
  background:
    "linear-gradient(225deg, #731686 1%, transparent 10%, transparent 80%, #af22c8 100%)", // Linear gradient background
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  paddingBottom: "20px",
};

const contentStyle = {
  position: "relative",
  top: "0px",
  width: "100%",
};

const ForSale = () => {
  let lastScrollPosition = 0;
  let is90vhReached = false;
  let heightOfPage = document.documentElement.scrollHeight;
  const [nftForSale, setNftForSale] = useState([]);

  const [pages, setPages] = useState(1);
  const [loadingPage, setLoadingPage] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(1); // Initialize offset with 0
  const { loading, error, forSaleNft } = useSelector((state) => state.nft);
  const { offset: offsetState } = useSelector((state) => state.offset);

  const {
    loading: myNftLoading,
    transferNft,
    sellOfferCancelSatate,
    sellNFT,
    buyDirectNFTState,
    approveOfferNft,
    acceptTransferRes,
    buyNFTState,
  } = useSelector((state) => state.mynft);
  const { userId } = useSelector((state) => state.userNft);

  useEffect(() => {
    // Cleanup function
    return () => {
      setNftForSale([]);
      // or setNftForSale([]) for an empty array, or setNftForSale({}) for an empty object
    };
  }, []);
  useEffect(() => {
    setOffset(1);
  }, [offsetState]);

  useEffect(() => {
    if (transferNft?.data?.status === true) {
      toast.success(transferNft?.data?.message);
      getData(offset);
      dispatch(resetTransferNftState());
    } else if (transferNft?.data?.status === false) {
      toast.error(transferNft?.data?.message);
      dispatch(resetTransferNftState());
    }
  }, [transferNft?.data?.status]);

  useEffect(() => {
    if (buyNFTState?.data?.status === true) {
      toast.success(buyNFTState?.data?.message);
      getData(offset);
      dispatch(resetBuyOfferState());
    } else if (buyNFTState?.data?.status === false) {
      toast.error(buyNFTState?.data?.message);
      dispatch(resetBuyOfferState());
    }
  }, [buyNFTState?.data?.status]);

  useEffect(() => {
    if (buyDirectNFTState?.data?.status === true) {
      toast.success(buyDirectNFTState?.data?.message);
      getData(offset);
      dispatch(resetBuyDirectNftState());
    } else if (buyDirectNFTState?.data?.status === false) {
      toast.error(buyDirectNFTState?.data?.message);
      dispatch(resetBuyDirectNftState());
    }
  }, [buyDirectNFTState?.data?.status]);

  useEffect(() => {
    if (approveOfferNft?.data?.status === true) {
      toast.success(approveOfferNft?.data?.message);
      getData(offset);
      dispatch(resetapproveOfferNft());
    } else if (approveOfferNft?.data?.status === false) {
      toast.error(approveOfferNft?.data?.message);
      dispatch(resetapproveOfferNft());
    }
  }, [approveOfferNft?.data?.status]);

  useEffect(() => {
    if (sellOfferCancelSatate?.data?.status === true) {
      toast.success(sellOfferCancelSatate?.data?.message);
      getData(offset);
      dispatch(resetsellOfferCancelState());
    } else if (sellOfferCancelSatate?.data?.status === false) {
      toast.error(sellOfferCancelSatate?.data?.message);
      dispatch(resetsellOfferCancelState());
    }
  }, [sellOfferCancelSatate?.data?.status]);

  useEffect(() => {
    if (sellNFT?.data?.status) {
      toast.success(sellNFT?.data?.message);
      getData(offset);
      dispatch(resetSellState());
    } else if (sellNFT?.data?.status === false) {
      toast.error(sellNFT?.data?.message);
      dispatch(resetSellState());
    }
  }, [sellNFT?.data?.status]);

  useEffect(() => {
    if (acceptTransferRes?.data?.status) {
      toast.success(acceptTransferRes?.data?.message);
      getData(offset);
      dispatch(resetAcceptTransferRes());
    } else if (acceptTransferRes?.data?.status === false) {
      toast.error(acceptTransferRes?.data?.message);
      dispatch(resetAcceptTransferRes());
    }
  }, [acceptTransferRes?.data?.status]);

  const fetchData = () => {
    let account = localStorage.getItem("accnum");
    const apiData = {
      limit: 24,
      offset: offset,
      userAccount: account,
    };
    dispatch(recentlyForSaleNft(apiData));
  };
  function getData(offset) {
    if (offset === 1) {
      fetchData();
    } else if (offset <= pages) {
      fetchData();
    }
  }
  useEffect(() => {
    getData(offset);
  }, [offset]);

  function handleScroll() {
    const scrollPosition = window.scrollY;
    const viewportHeight = window.innerHeight;
    const pageHeight = heightOfPage;

    if (
      !forSaleNft.loading &&
      scrollPosition >= lastScrollPosition + 0.9 * viewportHeight
    ) {
      const newOffset = Math.floor(scrollPosition / (0.9 * viewportHeight));
      setOffset(newOffset + 1); // Add 1 because indexing starts from 0
      lastScrollPosition = newOffset * 0.9 * viewportHeight;
    }

    // Check if 90vh is reached
    if (!is90vhReached && scrollPosition >= pageHeight) {
      setOffset((prevOffset) => prevOffset + 1);
      is90vhReached = true;
    }

    // Reset is90vhReached when page reaches 90vh again
    if (is90vhReached && scrollPosition < 0.9 * pageHeight) {
      is90vhReached = false;
    }
  }
  function mergeArrays(existingData, newData) {
    // Ensure newData is not undefined or null
    const mergedData = newData ?? [];

    // Clear the existingData if offset is 1
    if (offsetState === 1 || offset === 1) {
      existingData = [];
      dispatch(resetOffsetState());
    }

    // Iterate over the new data and push each element to the existing array
    mergedData.forEach((item) => {
      existingData.push(item);
    });

    // Return the updated existing array
    return existingData;
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    return () => {
      // Perform cleanup here, for example, resetting the data
      setNftForSale([]);
    };
  }, []);

  useEffect(() => {
    setNftForSale((prevNFTs) => mergeArrays(prevNFTs, forSaleNft?.data?.nfts));

    if (offset === 1) {
      setPages(forSaleNft?.data?.total_pages);
    }
  }, [forSaleNft]);

  useEffect(() => {
    // Set loadingPage to true if either myNftLoading or nftLoading is true
    setLoadingPage(myNftLoading || loading);
  }, [myNftLoading, loading]);

  function renderContent() {
    if (loadingPage && !nftForSale.length > 0) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh", // Set minimum height to fill the entire viewport
          }}
        >
          <CustomLoader width={"60%"} text={"Please wait ..."} />{" "}
        </Box>
      );
    } else if (nftForSale && nftForSale.length > 0) {
      return (
        <Box sx={{ display: "flex", flexDirection: "column" }} width={"100%"}>
          <Grid container spacing={4} sx={{ paddingInline: 4 }}>
            {nftForSale &&
              nftForSale?.map((items) => (
                <Grid
                  item
                  xl={3}
                  lg={3}
                  sm={6}
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                  key={items.id}
                >
                  <NFTCard nft={items} borderColor="#AF22C8" />
                </Grid>
              ))}
          </Grid>
          {loadingPage && (
            <Box height={"100vh"}>
              {" "}
              <CustomLoader width={"15%"} text={"Please wait ..."} />
            </Box>
          )}
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: "flex", justifyContent: "center", pt: 6 }}>
          {!loadingPage && (
            <Typography
              variant="h6"
              sx={{ color: "#f1f1f1", fontFamily: "goodTime" }}
            >
              No NFT's Found!
            </Typography>
          )}
        </Box>
      );
    }
  }

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <ScrollToTopOnMount />
        <Grid container spacing={2}>
          <Grid item xl={2} lg={2} sm={2} xs={1}>
            {" "}
          </Grid>
          <Grid item xl={8} lg={8} sm={8} xs={10}>
            <Box sx={{ py: 2, pb: 4 }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 600,
                  color: "white",
                  textAlign: "center",
                  fontFamily: "goodTime",
                  py: 2,
                }}
              >{`Recently for Sale`}</Typography>
            </Box>
          </Grid>
          <Grid item xl={2} lg={2} sm={2} xs={1}></Grid>
        </Grid>
        <Divider
          variant="middle"
          sx={{ mb: 4, backgroundColor: "#AF22C8", mx: 6 }}
        />
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {renderContent()}
        </Box>
      </div>
    </div>
  );
};

export default ForSale;
