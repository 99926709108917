import React, { useEffect, useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Show the arrow when the user scrolls down, hide it when at the top
    const handleScroll = () => {
      const isScrolled = window.scrollY > 100; // You can adjust the value based on your design
      setIsVisible(isScrolled);
    };

    // Event listener for scroll
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: smooth scrolling animation
    });
  };

  return (
    <div>
      {isVisible && (
        <div
          onClick={scrollToTop}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#AF22C8",
            color: "#fff",
            width: 40,
            height: 40,
            borderRadius: 20,
            cursor: "pointer",
            zIndex: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ArrowUpwardIcon />
        </div>
      )}
    </div>
  );
}

export default ScrollToTop;
