import React, { useEffect, useState } from "react";
import "./Appbar.css";
import {
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  useMediaQuery,
  useScrollTrigger,
  AppBar,
  Toolbar,
  Slide,
} from "@mui/material";
import PropTypes from "prop-types";

import toast from "react-hot-toast";
import MediaCard from "../../../../features/CategorryCard/Card";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Logo from "../../../../../assets/logos/DeLuxMintLogo.svg";
// import Logo from "../../../../../assets/logos/DeLuxMintLogo.png";

import Login from "../../../../features/Login/login";
import { axiosRequestAuth } from "../../../../../services/api/api";
import SearchCard from "../../../../shared/searchCard/searchCard";
import TabPanel from "../../../../../utils/TabPanels/Tabpanels";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useSelector } from "react-redux/es/hooks/useSelector";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet"; // Example icon for NFT Results

import CollectionsIcon from "@mui/icons-material/Collections"; // Icon for Collection Results
import CustomLoader from "../../../../shared/customLoader/customLoader";
import UserCollectionCard from "../../../../features/UserCollectionCard/UserCollectionCard";
import {
  setCollectionId,
  setCollectionName,
  setTaxonId,
} from "../../../../../services/redux/CollectionReducer/CollectionReducer";
import { useDispatch } from "react-redux";

const tabStyle = {
  fontSize: "14px", // Adjust the font size as needed
  fontWeight: 500,
};

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

function Appbar() {
  const isAdmin2 = localStorage.getItem("isAdmin");
  const dispatch = useDispatch();
  // const isAdmin = true;
  const isAuthenticated = localStorage.getItem("token");

  const [exploreAnchorEl, setExploreAnchorEl] = useState(null);
  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  const [adminAnchorEl, setAdminAnchorEl] = useState(null);
  const [nfts, setNfts] = useState([]);
  const [collection, setCollection] = useState([]);

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [query, setQuery] = useState("");
  const [isFeatured, setIsFeatured] = useState(false);

  const [tabValue, setTabValue] = useState(1);
  const {
    nftCount,
    isAdmin,
    // token: isAuthenticated,
    allCounts,
  } = useSelector((state) => state.userNft);

  console.log(isAuthenticated, "dfdfsfdsfsdfsf");
  // console.log(isAdmin, "isAdmin isAdmin");
  const navigate = useNavigate();
  const is1024ScreenSize = useMediaQuery("(min-width:1024px)");

  const handleExploreClick = (event) => {
    setExploreAnchorEl(event.currentTarget);
  };
  const handleExploreClose = () => {
    setExploreAnchorEl(null);
  };
  const handleAccountClick = (event) => {
    setAccountAnchorEl(event.currentTarget);
  };
  const handleCollectionsClick = () => {
    navigate("/xrp-collections");

    handleExploreClose();
  };
  const handleUSDtoXRPClick = () => {
    navigate("/usd-collections");

    handleExploreClose();
  };
  const handleCollectionsDameClick = () => {
    navigate("/game-collections");
    handleExploreClose();
  };

  const handleAdminClick = (event) => {
    setAdminAnchorEl(event.currentTarget);
  };
  const handleAccountClose = () => {
    setAccountAnchorEl(null);
  };
  const handleAdminClose = () => {
    setAdminAnchorEl(null);
  };
  // const handleNavigationMyNfts = () => {
  //   handleAccountClose();
  //   navigate("nfts/myNFTs");
  // };
  const handleNavigationMinting = () => {
    if (isAuthenticated) {
      navigate("/collections-mint");
    } else {
      toast.error("You need to login first to access NFTs");
    }
    handleAdminClose();
  };
  const handleNavigationAdmin = () => {
    if (isAuthenticated) {
      navigate("/admins");
    } else {
      toast.error("You need to login first to access NFTs");
    }
    handleAdminClose();
  };
  const handleNavigationAdminFeatured = () => {
    if (isAuthenticated) {
      navigate("/admin/featured");
    } else {
      toast.error("You need to login first to access NFTs");
    }
    handleAdminClose();
  };
  const handleNavigationForSale = () => {
    navigate("/recently-for-sale");

    handleExploreClose();
  };
  const handleNavigationCategory = () => {
    if (isAuthenticated) {
      navigate("/admin/categories");
    } else {
      toast.error("You need to login first to access NFTs");
    }
    handleAdminClose();
  };

  const handleBulkMintDetail = () => {
    if (isAuthenticated) {
      navigate("/bulk-mint-detail");
    } else {
      toast.error("You need to login first to access NFTs");
    }
    handleAdminClose();
  };

  const handleBulkTransferDetail = () => {
    if (isAuthenticated) {
      navigate("/bulk-transfer-details");
    } else {
      toast.error("You need to login first to access NFTs");
    }
    handleAdminClose();
  };

  const handleSyncNfts = () => {
    if (isAuthenticated) {
      navigate("/sync-nfts");
    } else {
      toast.error("You need to login first to access NFTs");
    }
    handleAdminClose();
  };

  const handleNavigationCarousel = () => {
    if (isAuthenticated) {
      navigate("/admin/carousel");
    } else {
      toast.error("You need to login first to access NFTs");
    }
    handleAdminClose();
  };
  const handleNavigationUserWallet = () => {
    if (isAuthenticated) {
      navigate("/user-mints");
    } else {
      toast.error("You need to login first to access NFTs");
    }
    handleAdminClose();
  };
  const handleNavigationUserHistory = () => {
    if (isAuthenticated) {
      navigate("/mint-history");
    } else {
      toast.error("You need to login first to access NFTs");
    }
    handleAdminClose();
  };

  const handleChange = (event, newValue) => {
    setTabValue(parseInt(newValue));

    // Define the route names corresponding to each tab

    // Use the navigate function to change the route
  };

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };
  const handleClose = (e) => {
    setOpenModal(false);
    setQuery("");
  };
  const handleData = (e) => {
    setOpenModal(false);
    dispatch(setTaxonId(e.taxon_id));
    dispatch(setCollectionName(e.name));
    dispatch(setCollectionId(e.id));

    // setTimeout(() => {
    navigate("/nfts/collection-nfts");
    // }, 2000); // 2000 milliseconds = 2 seconds
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Call your function here
      GetSearch();
      setOpenModal(true);
    }
  };

  async function GetSearch() {
    setLoading(true);

    try {
      const response = await axiosRequestAuth("post", "v2/search/global", {
        searchQuery: query,
      });
      setNfts(response?.data?.data?.nfts);

      setCollection(response?.data?.data?.collections);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  }
  useEffect(() => {
    if (!openModal) {
      // Reset the query when the modal is closed
      setQuery("");
    }
  }, [openModal]);

  return (
    <>
      <Box
        sx={{
          height: "80px",
          width: "100%",
        }}
      >
        <HideOnScroll>
          <AppBar
            sx={{ background: "#000000", boxShadow: "none", height: "80px" }}
          >
            <Toolbar>
              <Box width={"100%"} className="header" sx={{ pt: 0, mb: 1 }}>
                <Grid
                  container
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid item lg={2} md={2} sm={1}>
                    <Box
                      sx={{
                        pb: 1,
                        mb: 1,
                        ...(is1024ScreenSize ? { ml: 4 } : { ml: 2 }), // Conditionally apply styles
                      }}
                    >
                      <img
                        src={Logo}
                        height={95}
                        className="pointer-on-hover"
                        style={{
                          paddingBottom: 6,
                          paddingTop: 4,
                          marginBottom: 2,
                        }}
                        onClick={() => {
                          navigate("/");
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={2}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        pt: 3,
                      }}
                    >
                      <Box>
                        <Button
                          disableRipple
                          class="custom-button"
                          onClick={handleUSDtoXRPClick}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                              // fontWeight: "bold",
                              // fontFamily: "goodTime",
                            }}
                          >
                            Shop NFT's
                          </Typography>
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          disableRipple
                          class="custom-button"
                          onClick={handleExploreClick}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              // fontWeight: "bold",
                              // fontFamily: "goodTime",
                            }}
                          >
                            Explore
                            <ArrowDropDownIcon className="icon" />
                          </Typography>
                        </Button>
                        <Menu
                          disableRipple
                          className="menu"
                          anchorEl={exploreAnchorEl}
                          open={Boolean(exploreAnchorEl)}
                          onClose={handleExploreClose}
                        >
                          <MenuItem
                            onClick={handleNavigationForSale}
                            sx={{ px: 6, py: 2 }}
                          >
                            Recently for Sale
                          </MenuItem>
                          <MenuItem
                            onClick={handleUSDtoXRPClick}
                            sx={{ px: 6, py: 2 }}
                          >
                            USD Collections
                          </MenuItem>
                          <MenuItem
                            onClick={handleCollectionsClick}
                            sx={{ px: 6, py: 2 }}
                          >
                            XRP Collections
                          </MenuItem>
                          <MenuItem
                            onClick={handleCollectionsDameClick}
                            sx={{ px: 6, py: 2 }}
                          >
                            GAME Collections
                          </MenuItem>

                          {/* <MenuItem onClick={handleExploreClose} sx={{ px: 4, py: 1 }}>
                    Logout
                  </MenuItem> */}
                        </Menu>
                      </Box>

                      <Box>
                        <Button
                          disableRipple
                          class="custom-button"
                          onClick={handleAccountClick}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              // fontWeight: "bold",
                              // fontFamily: "poppins",
                            }}
                          >
                            Account
                            <ArrowDropDownIcon className="icon" />
                          </Typography>
                        </Button>
                        <Menu
                          className="menu"
                          anchorEl={accountAnchorEl}
                          open={Boolean(accountAnchorEl)}
                          onClose={handleAccountClose}
                        >
                          <MenuItem
                            onClick={() => {
                              if (isAuthenticated) {
                                navigate("nfts/myNFTs");
                              } else {
                                toast.error(
                                  "You need to log in first to access NFTs"
                                );
                              }
                              handleAccountClose();
                            }}
                            sx={{
                              px: 6,
                              py: 2,
                              display: "flex",
                              justifyContent: "space-between",
                              // backgroundColor: "purple", // Default purple background for MenuItem
                              color: "white", // Default white text for MenuItem
                              "&:hover": {
                                backgroundColor: "#aeaeae !important", // Change background on hover
                              },
                            }}
                          >
                            {"My NFTs"}
                            <Box
                              sx={{
                                position: "relative",
                                borderRadius: "4px",
                                padding: "4px 8px",
                                backgroundColor: "purple", // Default purple background for nftCounts
                                color: "white", // Default white text for nftCounts
                              }}
                            >
                              {allCounts?.total_nfts || 0}
                            </Box>
                          </MenuItem>

                          <MenuItem
                            onClick={() => {
                              if (isAuthenticated) {
                                navigate("nfts/myListedNFTs");
                              } else {
                                toast.error(
                                  "You need to login first to access NFTs"
                                );
                              }
                              handleAccountClose();
                            }}
                            sx={{
                              px: 6,
                              py: 2,
                              display: "flex",
                              justifyContent: "space-between",
                              // backgroundColor: "purple", // Default purple background for MenuItem
                              color: "white", // Default white text for MenuItem
                              "&:hover": {
                                backgroundColor: "#aeaeae !important", // Change background on hover
                              },
                            }}
                          >
                            My Listed NFTs
                            <Box
                              sx={{
                                position: "relative",
                                borderRadius: "4px",
                                padding: "4px 8px",
                                backgroundColor: "purple", // Default purple background for nftCounts
                                color: "white", // Default white text for nftCounts
                              }}
                            >
                              {allCounts?.sellOffers || 0}
                            </Box>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              if (isAuthenticated) {
                                navigate("nfts/offers");
                              } else {
                                toast.error(
                                  "You need to login first to access NFTs"
                                );
                              }
                              handleAccountClose();
                            }}
                            sx={{
                              px: 6,
                              py: 2,
                              display: "flex",
                              justifyContent: "space-between",
                              // backgroundColor: "purple", // Default purple background for MenuItem
                              color: "white", // Default white text for MenuItem
                              "&:hover": {
                                backgroundColor: "#aeaeae !important", // Change background on hover
                              },
                            }}
                          >
                            My Offers
                            <Box
                              sx={{
                                position: "relative",
                                borderRadius: "4px",
                                padding: "4px 8px",
                                backgroundColor: "purple", // Default purple background for nftCounts
                                color: "white", // Default white text for nftCounts
                              }}
                            >
                              {allCounts?.mybuyOffers || 0}
                            </Box>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              if (isAuthenticated) {
                                navigate("nfts/approvals");
                              } else {
                                toast.error(
                                  "You need to login first to access NFTs"
                                );
                              }
                              handleAccountClose();
                            }}
                            sx={{
                              px: 6,
                              py: 2,
                              display: "flex",
                              justifyContent: "space-between",
                              // backgroundColor: "purple", // Default purple background for MenuItem
                              color: "white", // Default white text for MenuItem
                              "&:hover": {
                                backgroundColor: "#aeaeae !important", // Change background on hover
                              },
                            }}
                          >
                            Waiting My Approval
                            <Box
                              sx={{
                                ml: 1,
                                position: "relative",
                                borderRadius: "4px",
                                padding: "4px 8px",
                                backgroundColor: "purple", // Default purple background for nftCounts
                                color: "white", // Default white text for nftCounts
                              }}
                            >
                              {allCounts?.mybuyOffersOnMyNFT || 0}
                            </Box>
                          </MenuItem>
                          {/* <MenuItem
                            onClick={() => {
                              if (isAuthenticated) {
                                navigate("free-mint");
                              } else {
                                toast.error(
                                  "You need to login first to access NFTs"
                                );
                              }
                              handleAccountClose();
                            }}
                            sx={{ px: 6, py: 2 }}
                          >
                            Free Mints
                          </MenuItem> */}
                          <MenuItem
                            onClick={() => {
                              if (isAuthenticated) {
                                navigate("nfts/transfer");
                              } else {
                                toast.error(
                                  "You need to login first to access NFTs"
                                );
                              }
                              handleAccountClose();
                            }}
                            sx={{
                              px: 6,
                              py: 2,
                              display: "flex",
                              justifyContent: "space-between",
                              // backgroundColor: "purple", // Default purple background for MenuItem
                              color: "white", // Default white text for MenuItem
                              "&:hover": {
                                backgroundColor: "#aeaeae !important", // Change background on hover
                              },
                            }}
                          >
                            Transfer
                            <Box
                              sx={{
                                position: "relative",
                                borderRadius: "4px",
                                padding: "4px 8px",
                                backgroundColor: "purple", // Default purple background for nftCounts
                                color: "white", // Default white text for nftCounts
                              }}
                            >
                              {allCounts?.TransferOffers || 0}
                            </Box>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              if (isAuthenticated) {
                                navigate("nfts/favorite");
                              } else {
                                toast.error(
                                  "You need to login first to access NFTs"
                                );
                              }
                              handleAccountClose();
                            }}
                            sx={{ px: 6, py: 2 }}
                          >
                            Favorite
                          </MenuItem>
                        </Menu>
                      </Box>
                      {isAdmin === true || isAdmin2 === "true" ? (
                        <Box>
                          <Button
                            disableRipple
                            class="custom-button"
                            onClick={handleAdminClick}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                // fontWeight: "bold",
                                // fontFamily: "poppins",
                              }}
                            >
                              Admin
                              <ArrowDropDownIcon className="icon" />
                            </Typography>
                          </Button>
                          <Menu
                            className="menu"
                            anchorEl={adminAnchorEl}
                            open={Boolean(adminAnchorEl)}
                            onClose={handleAdminClose}
                            // sx={{ p: 6 }}
                          >
                            <MenuItem
                              onClick={handleNavigationCategory}
                              sx={{ px: 6, py: 2 }}
                            >
                              Manage Categories
                            </MenuItem>
                            <MenuItem
                              onClick={handleNavigationCarousel}
                              sx={{ px: 6, py: 2 }}
                            >
                              Manage Collections
                            </MenuItem>

                            <MenuItem
                              onClick={handleNavigationAdminFeatured}
                              sx={{ px: 6, py: 2 }}
                            >
                              Featured NFTs
                            </MenuItem>
                            <MenuItem
                              onClick={handleNavigationAdmin}
                              sx={{ px: 6, py: 2 }}
                            >
                              Manage Admins
                            </MenuItem>
                            <MenuItem
                              onClick={handleNavigationUserWallet}
                              sx={{ px: 6, py: 2 }}
                            >
                              User Orders
                            </MenuItem>
                            <MenuItem
                              onClick={handleNavigationUserHistory}
                              sx={{ px: 6, py: 2 }}
                            >
                              Mint History
                            </MenuItem>
                            <MenuItem
                              onClick={handleNavigationMinting}
                              sx={{ px: 6, py: 2 }}
                            >
                              Mint NFTs
                            </MenuItem>

                            <MenuItem
                              onClick={handleBulkMintDetail}
                              sx={{ px: 6, py: 2 }}
                            >
                              Bulk Mint
                            </MenuItem>

                            <MenuItem
                              onClick={handleBulkTransferDetail}
                              sx={{ px: 6, py: 2 }}
                            >
                              Bulk Transfer
                            </MenuItem>
                            <MenuItem
                              onClick={handleSyncNfts}
                              sx={{ px: 6, py: 2 }}
                            >
                              Sync NFTs
                            </MenuItem>

                            {/* <MenuItem onClick={handleNavigationFeatured}>Featured</MenuItem> */}
                          </Menu>
                        </Box>
                      ) : (
                        <Box>
                          <Button
                            disableRipple
                            class="custom-button"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              navigate("/about");
                            }}
                          >
                            <Typography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                // fontWeight: "bold",
                                // fontFamily: "poppins",
                              }}
                            >
                              About
                            </Typography>
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={7}
                    sx={{
                      display: "flex",
                      direction: "row",
                      justifyContent: "end",
                    }}
                  >
                    <Box
                      sx={{
                        ...(is1024ScreenSize
                          ? { pt: 3.5, mr: 4 }
                          : { pt: 3.5, mr: 2 }), // Conditionally apply styles
                      }}
                    >
                      <TextField
                        key={openModal}
                        style={{ width: "230px" }}
                        size="small"
                        placeholder="Search"
                        onKeyPress={handleKeyPress}
                        onChange={handleSearch}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon sx={{ color: "#D7D1D1" }} />
                            </InputAdornment>
                          ),
                          sx: {
                            color: "#D7D1D1",
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                            borderRadius: 6,
                            "& .MuiOutlinedInput-notchedOutline": {},
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: " 2px solid #7F6C6C",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: " 2px solid #7F6C6C",
                            },
                            height: "35px",
                          },
                        }}
                        autoComplete="off" // Add this line to turn off auto-fill
                      />
                    </Box>

                    <Box
                      sx={{
                        ...(is1024ScreenSize
                          ? { mr: 4, pt: 3.5 }
                          : { mr: 2, pt: 3.5 }), // Conditionally apply styles
                      }}
                    >
                      <Login drawer={true} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Toolbar>
          </AppBar>
        </HideOnScroll>
      </Box>
      <Dialog
        open={openModal}
        onClose={handleClose}
        fullWidth
        maxWidth="md" // Increase the maxWidth for a wider dialog
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "#1a1a1a", // Dark black for the body background
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            // backgroundColor: "#333",
            color: "white",
            display: "flex",
            justifyContent: "space-between", // Space between the items
            alignItems: "center",
          }}
        >
          {/* <Typography variant="h6" sx={{ fontSize: "1.6rem" }}>
            Search
          </Typography> */}
          <Box width={"100%"} sx={{ px: 8, pt: 2 }}>
            <TextField
              key={openModal}
              size="small"
              fullWidth
              placeholder="Search"
              onKeyPress={handleKeyPress}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "grey" }} />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: 6,
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "rgba(255, 255, 255, 0.1)", // Glossy background color
                    borderRadius: "8px", // Border radius
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 14px", // Adjust input padding
                    color: "white", // Input text color
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove default border
                  },
                  "& .MuiOutlinedInput-input:focus": {
                    backgroundColor: "rgba(255, 255, 255, 0.1)", // Glossy background color on focus
                  },
                },
              }}
              autoComplete="off" // Add this line to turn off auto-fill
            />
          </Box>
          {/* <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 10,
              color: "white",
              backgroundColor: "#AF22C8",
              border: "3px solid white",
              borderRadius: "50%",
              // Adjust icon size by changing the fontSize
              fontSize: "1rem", // Smaller icon size
            }}
          >
            <CloseIcon sx={{ fontSize: "inherit" }} />
          </IconButton> */}
        </DialogTitle>

        <DialogContent dividers sx={{ color: "white" }}>
          <Box sx={{ p: 1, pb: 4, display: "flex", justifyContent: "center" }}>
            <Tabs
              onChange={handleChange}
              value={tabValue}
              variant="scrollable"
              scrollButtons="auto"
              TabScrollButtonProps={{
                sx: { color: "white" },
              }}
              TabIndicatorProps={{
                sx: { display: "none" },
              }}
              sx={{
                backgroundColor: "rgba(54, 69, 79, 0.3)",
                borderRadius: 2,
                fontSize: 6,
                "& button.Mui-selected": {
                  color: "white",
                  borderRadius: 2,
                  backgroundColor: "#af22c8",
                  transition: "background-color 0.3s ease-in-out", // Add smooth transition
                },
                "& button:not(.Mui-selected):hover": {
                  opacity: 0.8,
                  borderRadius: 2,
                  transition: "color 0.3s ease-in-out", // Add smooth transition
                },
              }}
            >
              <Tab
                sx={{
                  fontFamily: "goodTime",
                  color: "#DCDDDD",
                  p: { xs: 1, sm: 2 }, // smaller padding on extra small screens
                }}
                label="Collection"
                value={1}
                style={tabStyle}
              />
              {/* <Tab
                sx={{
                  fontFamily: "goodTime",
                  color: "#DCDDDD",
                  p: { xs: 1, sm: 2 }, // smaller padding on extra small screens
                }}
                label="Collections"
                value={2}
                style={tabStyle}
              /> */}
            </Tabs>
          </Box>
          <TabPanel value={tabValue} sx={{ color: "white" }} index={2}>
            {loading ? (
              <CustomLoader width="20%" text={"Please wait ..."} />
            ) : (
              <>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1.8rem", // Increased font size
                    mt: 3,
                    color: "#AF22C8",
                  }}
                >
                  <AccountBalanceWalletIcon
                    sx={{ mr: 1, color: "inherit", fontSize: "inherit" }}
                  />{" "}
                  NFT Results
                </Typography>
                <Typography
                  gutterBottom
                  sx={{ fontSize: "1.25rem", color: "#AF22C8" }}
                >
                  <Grid container spacing={2}>
                    {!nfts || nfts.length === 0 ? (
                      <Box
                        sx={{
                          pt: 6,
                          p: 2,
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Typography variant="h6">No NFT's Found!</Typography>
                      </Box>
                    ) : (
                      nfts?.map((items) => (
                        <Grid
                          item
                          xl={4}
                          lg={4}
                          sm={6}
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                          key={items.id}
                        >
                          <SearchCard
                            data={items}
                            forSale={false}
                            isFeatured={isFeatured}
                          />
                        </Grid>
                      ))
                    )}
                  </Grid>
                </Typography>
              </>
            )}
          </TabPanel>
          {/* ///////////      collections   ///////////// */}
          <TabPanel value={tabValue} sx={{ color: "white" }} index={1}>
            {loading ? (
              <CustomLoader width="20%" text={"Please wait ..."} />
            ) : (
              <>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1.8rem", // Increased font size
                    mt: 3,
                    color: "#AF22C8",
                  }}
                >
                  <CollectionsIcon
                    sx={{ mr: 1, color: "inherit", fontSize: "inherit" }}
                  />{" "}
                  Collection Results
                </Typography>
                <Typography
                  gutterBottom
                  sx={{ fontSize: "1.25rem", color: "#AF22C8" }}
                >
                  {!collection || collection.lenght == 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        pt: 6,
                        p: 2,
                        width: "100%",
                      }}
                    >
                      <Typography variant="h6">No Collection Found!</Typography>
                    </Box>
                  ) : (
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        mt: 2,
                        paddingInline: 4,
                        justifyContent: "center",
                      }}
                    >
                      {collection &&
                        collection?.map((item, index) => (
                          <Grid
                            key={index}
                            item
                            lg={6}
                            sm={6}
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              py: 4,
                              // px: 4,
                              pb: 2,
                            }}
                          >
                            <UserCollectionCard
                              data={item}
                              onConfirm={handleData}
                            />
                          </Grid>
                        ))}
                    </Grid>
                  )}
                </Typography>
              </>
            )}
          </TabPanel>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Appbar;
