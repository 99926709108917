import * as React from "react";

import { imageURL } from "../../../services/api/api";
import { useLocation } from "react-router-dom";
import { Paper, Typography, Box, Card, Grid } from "@mui/material";

const UserCollectionCard = ({ data, onConfirm }) => {
  let height = 305;
  const location = useLocation();
  return (
    <>
      <Card
        sx={{
          background: "black",
          minWidth: height,
          maxWidth: 345,
          borderRadius: 4,
          cursor: "pointer",
          transition: "transform 0.3s",
          boxShadow: "0 0 10px rgba(125, 60, 224, 0.4)",

          ":hover": {
            transform: "scale(1.05)",
            boxShadow: "0 0 2 0px rgba(125, 60, 224, 0.5)",
          },
          border: "solid 2px #AF22C8 ",
        }}
        onClick={() => onConfirm(data)}
      >
        <Paper sx={{ background: "black" }}>
          <Box
            sx={{
              pt: 1,

              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Box sx={{ px: 1 }}>
              <img
                style={{
                  width: "100%",
                  padding: 0,
                }}
                src={`${imageURL}${data?.thumbnail}`}
                title="green iguana"
              />
            </Box>
            {/* //////////////////////////////////////////    Image above      //////////////////////////   */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",

                px: 2,
              }}
            >
              <Box>
                <Typography
                  variat="h6"
                  sx={{
                    color: "white",
                    fontSize: 13,
                    fontWeight: 600,
                    py: 1,
                    color: "#AF22C8",
                    fontFamily: "goodTime",
                  }}
                >
                  {data?.name
                    ? data?.name?.length > 30
                      ? `${data?.name?.slice(0, 30)}........`
                      : data?.name
                    : "N/A"}
                </Typography>
              </Box>
              {/* <Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: 13,
                    color: "white",
                    fontWeight: 600,
                    py: 1,
                    fontFamily: "goodTime",
                  }}
                >
                  {location.pathname.includes("nfts/myNFTs") ? (
                    <span>
                      <span style={{ color: "#AF22C8" }}>count</span>
                      {"  "}
                      <span style={{ color: "white" }}>{data?.taxon_id}</span>
                    </span>
                  ) : (
                    <span>
                      <span style={{ color: "#AF22C8" }}>Taxon</span>
                      {"  "}
                      <span style={{ color: "white" }}>{data?.taxon_id}</span>
                    </span>
                  )}
                </Typography>
              </Box> */}
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{
                  fontSize: 12,
                  color: "white",
                  textAlign: "start",
                  px: 2,
                  pb: 1,
                  pt: -2,
                  fontFamily: "goodTime",
                }}
              >
                <span>
                  <span style={{ color: "#AF22C8" }}>by</span>{" "}
                  <span style={{ color: "white" }}>
                    {data.user_account?.slice(0, 20)}...
                  </span>
                </span>
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Card>
    </>
  );
};

export default UserCollectionCard;
