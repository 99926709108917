import React, { useEffect } from "react";
import "./Header.css";
import CustomDrawer from "./Drawer/Drawer";
import Appbar from "./Appbar/Appbar";
import { useMediaQuery } from "@mui/material";
import { socket } from "../../../../App";
import { useDispatch } from "react-redux";
import {
  setApiTimer,
  setCountNFT,
} from "../../../../services/redux/UserNFTCountReducer/UserNFTCountReducer";
import { axiosRequestAuth } from "../../../../services/api/api";

const Header = () => {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   const userNFTs = async () => {
  //     try {
  //       const response = await axiosRequestAuth(
  //         "get",
  //         "v2/user/nfts-count",
  //         {}
  //       );
  //       if (response) {
  //         dispatch(setApiTimer(response.data.data.synced));
  //         dispatch(setCountNFT(response.data.data.total_nfts));
  //       }
  //     } catch (error) {
  //       console.error("Error fetching NFTs:", error);
  //     }
  //   };
  //   // Listen to the socket event
  //   socket.on("nftsold", async (data) => {
  //     console.log("Data received from socket:", data);
  //     await userNFTs();

  //     // Make an API call
  //     // axios
  //     //   .post("http://your-api-endpoint", data)
  //     //   .then((response) => {
  //     //     console.log("API response:", response.data);
  //     //   })
  //     //   .catch((error) => {
  //     //     console.error("Error making API call:", error);
  //     //   });
  //   });

  //   // Cleanup socket connection on component unmount
  //   return () => {
  //     socket.off("nftsold");
  //   };
  // }, [socket, dispatch]);

  const isBelow1024px = useMediaQuery("(max-width: 1024px)");
  return <>{isBelow1024px ? <CustomDrawer /> : <Appbar />}</>;
};

export default Header;
