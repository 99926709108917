import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  offersAcceptedCount: 0,
  offersCreatedCount: 0,
  offerCreatedNotifications: [],
  offerAcceptedNotifications: [],
};

const notificationSlice = createSlice({
  name: "notify",
  initialState,
  reducers: {
    setNotifications(state, action) {
      state.offersAcceptedCount = action.payload.offersAcceptedCount;
      state.offersCreatedCount = action.payload.offersCreatedCount;
      state.offerCreatedNotifications =
        action.payload.offerCreatedNotifications;
      state.offerAcceptedNotifications =
        action.payload.offerAcceptedNotifications;
    },
  },
});

export const { setNotifications } = notificationSlice.actions;

export default notificationSlice.reducer;
