import React, { useEffect, useState } from "react";
import CustomTable from "../../../../components/shared/customTable/customTable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";

import BackgroundWrapper from "../../../../components/layout/Shared/BackgroundWrapper/BackgroundWrapper";
import {
  Grid,
  Box,
  Typography,
  Button,
  Modal,
  Paper,
  IconButton,
} from "@mui/material";

import toast from "react-hot-toast";

import { useNavigate } from "react-router-dom";

import CustomLoader from "../../../../components/shared/customLoader/customLoader";
import {
  getFreeMintAllUsersAPI,
  resetGetFreeMintAllUsersAPI,
} from "../../../../services/redux/MyNftApiReducer/MyNftApiReducer";
import { useDispatch, useSelector } from "react-redux";

function UserHistory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [userHistory, setUserHistory] = useState([]);
  const { getFreeMintAllUsersData, loading: loader } = useSelector(
    (state) => state.mynft
  );
  const txtcolor = "#f1f1f1";
  // Handle the edit action
  const bgcolor = "rgba(104, 103, 103, 0.5)";
  const columns = [
    { key: "orderTrackingId", label: "Order ID", dataType: "text" },
    { key: "collectionName", label: "Collection Name", dataType: "text" },
    { key: "NFToken", label: "NFT Token ID", dataType: "text" },
    { key: "walletAddress", label: "Wallet Address", dataType: "text" },
    { key: "createdAt", label: "Created At", dataType: "date" },
  ];

  useEffect(() => {
    dispatch(getFreeMintAllUsersAPI());
  }, []);

  useEffect(() => {
    if (getFreeMintAllUsersData?.data?.status) {
      setUserHistory(getFreeMintAllUsersData?.data?.data || []);
      dispatch(resetGetFreeMintAllUsersAPI());
    }
  }, [getFreeMintAllUsersData?.data?.status]);

  useEffect(() => {
    setLoading(loader);
  }, [loader]);

  return (
    <BackgroundWrapper>
      <Grid container spacing={1} pl={5} pr={10}>
        <Grid item lg={2}>
          {" "}
          <Box sx={{ display: "flex", justifyContent: "start", pt: 3, ml: 1 }}>
            <Button
              size="large"
              sx={{ color: txtcolor }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon fontSize="large" sx={{ color: txtcolor }} />
            </Button>
          </Box>
        </Grid>
        <Grid item lg={8}>
          <Box sx={{ display: "flex", justifyContent: "center", pt: 2, pb: 4 }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                color: "white",
                textAlign: "center",
                py: 2,
              }}
            >{`Mint History`}</Typography>
          </Box>
        </Grid>
        <Grid item lg={2}></Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid xl={2} lg={2} md={2} sm={0} xs={0} />
        <Grid xl={8} lg={8} md={8} sm={12} xs={12}>
          <>
            {!loading ? (
              <CustomTable
                data={userHistory}
                editable={false}
                columns={columns}
                // onEditClick={handleEditClick}
                // onDeleteClick={handleDeleteClick}
                showVisibleIcon={true}
                actions={false}
              />
            ) : (
              <CustomLoader width="20%" text={"Please wait ..."} />
            )}
          </>
        </Grid>
        <Grid xl={2} lg={2} md={2} sm={0} xs={0} />
      </Grid>
    </BackgroundWrapper>
  );
}

export default UserHistory;
