// AdminRoute.js
import React from "react";
import toast from "react-hot-toast";
import { Navigate } from "react-router-dom";

const AdminRoute = ({ children }) => {
  // const { isAdmin } = useSelector((state) => state.userNft);
  const isAuthenticated = localStorage.getItem("token");
  const isAdmin = localStorage.getItem("isAdmin");

  console.log(isAuthenticated, isAdmin, "token");

  // const isAdmin = localStorage.getItem("isAdmin");
  // const isAdmin = true;
  //
  if ((isAuthenticated && isAdmin === false) || !isAuthenticated) {
    toast.error("You do not have permission to access this page");
  }

  if ((isAuthenticated && isAdmin === false) || !isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default AdminRoute;
