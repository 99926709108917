import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  xumObj: {},
  account: "",
};
export const loginSlice = createSlice({
  name: "xumm",
  initialState,
  reducers: {
    login: (state, action) => {
      state.account = action.payload?.me?.account;
      state.xumObj = action.payload;
      console.log(action, "login");
    },
    logout: (state) => {
      state.account = "";
      state.xumObj = {};
    },
  },
});
export const { login, logout } = loginSlice.actions;

export default loginSlice.reducer;
