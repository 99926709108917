// PrivateRoute.js
import React from "react";
import toast from "react-hot-toast";
import { Navigate } from "react-router-dom";
import { createRemoteJWKSet, jwtVerify } from "jose";
import { useSelector } from "react-redux";

const XUMM_JWKS_URL = "https://oauth2.xumm.app/certs";

function getXummJwks() {
  return createRemoteJWKSet(new URL(XUMM_JWKS_URL));
}
const PrivateRoute = ({ children }) => {
  // const { token: isAuthenticated } = useSelector((state) => state.userNft);
  const isAuthenticated = localStorage.getItem("token");

  if (!isAuthenticated) {
    toast.error("You need to login first");
  }

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default PrivateRoute;
